import { ReactNode } from "react";
import styled from "styled-components";
import {Image, ImageProps} from 'antd-mobile'
import { Badge } from "antd";

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Button = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    cursor:pointer;
`

const Title= styled.span`
    text-align: center;
    font-size: 18px;
    color: #000000;
    font-weight: 800;
    margin-top: 10px;
`

const FunctionButton = (props:{image:{src:string},title:string,clickEvent:React.MouseEventHandler<HTMLDivElement>}) => {
    return(
        <ButtonContainer>
            <Button onClick={props.clickEvent}>
                <Image src={props.image.src} alt='button' fit='fill' />
            </Button>
            <Title>
                {props.title}
            </Title>
        </ButtonContainer>
    )
}

export default FunctionButton;