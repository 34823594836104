import { gql } from "graphql-request"
import { authClient, makeGraphQLRequest } from "../url"

export const ApiLogin=async(email:string,password:string)=>{
    const queryStr=gql`
        query ApiLogin($email:String!,$password:String!){
            login(email:$email,password:$password){
                token
            }
        }
    `
    const variables={
        email:email,
        password:password
    }

    const res=makeGraphQLRequest<{login:{token:string}}>(queryStr,variables,authClient)


    const loginResult=await res
    const data={token:loginResult}

    return data;

}