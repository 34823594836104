import { Dropdown, DropdownRef, Radio, Space, TabBar } from 'antd-mobile'
import React, { useRef } from 'react'
import { SetOutline } from 'antd-mobile-icons'
import { RadioValue } from 'antd-mobile/es/components/radio'
import styled from 'styled-components'
import { ApiGetCurrentUserData } from '../../../graphql/query/getCurrentUserData'
import { notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

interface NavbarProps{
    setPageForceUpdate:React.Dispatch<React.SetStateAction<boolean>>
}

const NavbarItem=styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-right:10px;
    cursor:pointer;
`

export interface ServiceProps{
    id:string
    name:string
}

export const Navbar = (props:NavbarProps) => {
    const navigate=useNavigate()
    const ref = useRef<DropdownRef>(null)
    const [selectedSiteName, setSelectedSiteName] = React.useState<RadioValue>('')
    const [serviceData,setServiceData]=React.useState<ServiceProps[]>([])
    const { serviceId } = useParams<{ serviceId: string }>();

    React.useEffect(()=>{
        handleGetCurrentUserData()
    },[serviceId])

    const openFailedNotification = () => {
        notification.error({
            message: 'Error',
            description: 'Failed to get data',
        });
    }

    const handleGetCurrentUserData=()=>{
        ApiGetCurrentUserData()
        .then((res)=>{
            if(res.getCurrentUserData!==null){
                const services:ServiceProps[]=[]
                for(let i=0;i<res.getCurrentUserData.services.length;i++){
                    services.push({id:res.getCurrentUserData.services[i]?.id,name:res.getCurrentUserData.services[i]?.name})
                    if(res.getCurrentUserData.services[i]?.id===serviceId){
                        setSelectedSiteName(res.getCurrentUserData.services[i]?.name)
                    }
                }
                setServiceData(services)
            }
            else{
                openFailedNotification()
            }
        })
        .catch((error:any)=>{
            openFailedNotification()
        })
    }

  return (
    <>
        <Dropdown ref={ref} style={{background:'#75BD76',fontSize:'16px'}} closeOnMaskClick={false} closeOnClickAway={false}>
            <Dropdown.Item key='sorter' title={<div style={{display:'flex',alignItems:'center'}}><div style={{fontSize:'18px',color:'#ffffff',fontWeight:'800'}}>{selectedSiteName}</div></div>} >
                <div style={{ padding: 12 }}>
                    <Radio.Group 
                        onChange={(value:RadioValue)=>{ 
                            ref.current?.close()
                            for(let i=0;i<serviceData.length;i++){
                                if(serviceData[i].id===value){
                                    navigate("/services/"+serviceData[i].id.toString())
                                }
                            }
                        }}
                    >
                        <Space direction='vertical' block>
                            {
                                serviceData.map((service:ServiceProps)=>{
                                    return(
                                        <Radio block value={service.id} key={service.id} className='colorPrimary'>
                                            {service.name}
                                        </Radio>
                                    )
                                })
                            }
                        </Space>
                    </Radio.Group>
                </div>
            </Dropdown.Item>
        </Dropdown>
        
        <NavbarItem>
            <SetOutline color='#ffffff' fontSize={32} fontWeight={800} onClick={()=>{navigate("/services/"+serviceId+'/sys-settings')}}/>
        </NavbarItem>
    </>
  )
}
