import { ConfigProvider, FloatButton, Spin, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { LeftOutline } from 'antd-mobile-icons'
import React, { useEffect } from 'react'
import { PageLayout } from '../../components/pageLayout'
import { ActionSheet, List, NavBar } from 'antd-mobile'
import { ApiGetServiceByID } from '../../../graphql/query/getServiceById'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NewMemberPage } from './newMember'
import { ApiGetCurrentUserData } from '../../../graphql/query/getCurrentUserData'
import { ServiceProps } from '../navbar'
import { EditMemberPage } from './editMember'
import { ApiRemoveUserFromService } from '../../../graphql/mutation/removeUserFromService'

interface ItemDataProps{
    id:string
    userId:string
    title:string
    email:string
}

export const MemberPage = () => {
  const [loading, setLoading] = React.useState(false)
  const [itemData,setItemData]=React.useState<ItemDataProps[]>([])
  const [addMemberBtnVisible,setAddMemberBtnVisible]=React.useState(false)
  const [actionSheetVisible,setActionSheetVisible]=React.useState({itemId:"",itemName:"",open:false})
  const [forceUpdate,setForceUpdate]=React.useState(false)
  const location=useLocation()
  const navigate=useNavigate()
  const { serviceId } = useParams<{ serviceId: string }>();

  useEffect(()=>{
    handleGetMemberData()
    handleGetCurrentUserData()
  },[])

  useEffect(()=>{
    handleGetMemberData()
  },[forceUpdate])

  const handleGetMemberData=()=>{
    setLoading(true)
    ApiGetServiceByID(serviceId||'')
    .then((res:any)=>{
        if(res.getServiceByID!==null){
            const members=res.getServiceByID.serviceUsers.sort((a:any,b:any)=>a.callPriority-b.callPriority)
            const data:ItemDataProps[]=[]
            members.map((item:any)=>{
                data.push({
                    id:item.id,
                    userId:item.user.id,
                    title:item.user.name,
                    email:item.user.description
                })
            })
            setItemData(data)
        }
    })
    .finally(()=>{
        setLoading(false)
    })
  }

  const handleRemoveMember=(userId:string)=>{
    setLoading(true)
    ApiRemoveUserFromService(userId,serviceId||'')
    .then((res:any)=>{
        if(res.removeUserFromService===true){
            setActionSheetVisible({itemId:"",itemName:"",open:false})
        }
        else{
            setActionSheetVisible({itemId:"",itemName:"",open:false})
        }
        setForceUpdate((prev)=>!prev)
    })
    .catch((error:any)=>{
        setActionSheetVisible({itemId:"",itemName:"",open:false})
    })
    .finally(()=>{
        setLoading(false)
    })
  }

    const handleGetCurrentUserData=()=>{
        ApiGetCurrentUserData()
        .then((res:any)=>{
            if(res.getCurrentUserData!==null){
                if(res.getCurrentUserData.adminServices.length===0){//非管理員
                    setAddMemberBtnVisible(false)
                }
                else if(res.getCurrentUserData.adminServices.length>0){//管理員=>判斷是否在當前的service有權限
                    res.getCurrentUserData.adminServices.map((item:ServiceProps)=>{
                        if(item.id===serviceId){
                            setAddMemberBtnVisible(true)
                        }
                    })
                }
            }
            else{
                setAddMemberBtnVisible(false)
            }
        })
        .catch((error:any)=>{
            setAddMemberBtnVisible(false)
        })
    }


  return (
      <>
          {
            location.pathname==="/services/"+serviceId+'/member'&&
            <>
                <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
                <PageLayout 
                    header={
                        <NavBar backIcon={<LeftOutline color='white' fontSize={18} style={{fontWeight:800}}/>} 
                            onBack={()=>{
                                navigate("/services/"+serviceId)
                            }}
                            right={
                                addMemberBtnVisible&&
                                <div style={{fontSize:'14px',color:'white',fontWeight:600,cursor:'pointer'}} onClick={(()=>{navigate("/services/"+serviceId+'/member/editMember')})}>
                                    EDIT
                                </div>
                            }
                            style={{width:'100vw'}}>
                            <div style={{fontSize:'18px',color:'white',textAlign:'center',fontWeight:800}}>
                                Member
                            </div>
                        </NavBar>
                    }
                    content={
                        <>
                        <List>
                        {
                            itemData.map((item:ItemDataProps,index:number)=>(
                            <List.Item
                                key={item.email}
                                style={{ paddingRight:'20px' }}
                                description={item.email}
                                onClick={(e)=>{setActionSheetVisible({itemId:item.userId,itemName:item.title,open:true})}}
                                clickable={false}
                            >
                                {item.title}
                            </List.Item>
                            ))
                        }
                        </List>
                        <ActionSheet
                            visible={actionSheetVisible.open}
                            actions={[
                                { text: `Remove ${actionSheetVisible.itemName}?`, key: 'diabled title',disabled:true },
                                { text: 'Remove', key: 'Remove',onClick:()=>handleRemoveMember(actionSheetVisible.itemId) },
                                { text: 'Cancel', key: 'Cancel',onClick:()=>setActionSheetVisible({itemId:"",itemName:"",open:false}) },
                            ]}
                        />
                        {
                            addMemberBtnVisible&&
                            <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token，影响范围大
                                    colorPrimary: '#75BD76',
                                },
                            }}
                            >
                                <FloatButton icon={<PlusOutlined />} type='primary' style={{ insetInlineEnd: 24 }} 
                                onClick={()=>{
                                    navigate("/services/"+serviceId+'/member/newMember')
                                }}/>
                            </ConfigProvider>
                        }
                    </>
                    }
                />
            </>
          }
          {
            location.pathname==="/services/"+serviceId+'/member/newMember'&&
            <NewMemberPage setForceUpdate={setForceUpdate}/>
          }
          {
            location.pathname==="/services/"+serviceId+'/member/editMember'&&
            <EditMemberPage setForceUpdate={setForceUpdate}/>
          }
      </>
  )
}
