import { useSortable } from '@dnd-kit/sortable'
import { List } from 'antd-mobile'
import { CSS } from '@dnd-kit/utilities'

interface ItemDataProps{
    id:string
    title:string
    email:string
}

export interface SortableItemProps {
    index:number
    item:ItemDataProps
}

export const SortableItem = (props:SortableItemProps) => {
    const {attributes,listeners,setNodeRef,transform,transition}=useSortable({id:props.item.id})
    const style={
        transform:CSS.Transform.toString(transform),
        touchAction:'none'
    }

  return (
    <div key={props.index+1} ref={setNodeRef} {...attributes} {...listeners} style={style}>
        <List.Item
            prefix={props.index+1}
            // key={props.item.email}
            style={{ paddingRight:'20px' }}
            description={props.item.email}
        >
            {props.item.title}
        </List.Item>
    </div>

  )
}
