import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest,  } from "../url"
import { ServiceUser } from "../model/application/serviceUser"
import { SERVER_TOKEN } from "../../idb"


export const ApiGetUserServiceFunctions=async(userId:string,serviceId:string)=>{
    const queryStr=gql`
        query ApiGetUserServiceFunctions($userId:String!,$serviceId:String!){
            getUserServiceFunctions(userID:$userId,serviceID:$serviceId){
                id
                userID
                baseFunctionID
                serviceID
                enabled
            }
        }
    `
    const variables={
        userId:userId,
        serviceId:serviceId,
    }
    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getUserServiceFunctions:ServiceUser}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get UserServiceFunctions");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting UserServiceFunctions");
    }

}