import { HomeLayout } from './layout'
import { Navbar } from './navbar'
import { Content } from './content'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'

export const HomePage = () => {
    const [pageForceUpdate,setPageForceUpdate]=useState(false)

    return (
        <>
            <HomeLayout
                header={
                    <Navbar setPageForceUpdate={setPageForceUpdate}/>
                }
                content={
                    <Content pageForceUpdate={pageForceUpdate}/>
                }
            />
            <Outlet />
        </>
    )
}
