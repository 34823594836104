import { ConfigProvider, FloatButton, notification, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../components/pageLayout'
import { List, NavBar } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import { ApiGetServiceByID } from '../../../../graphql/query/getServiceById'
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortableItem } from '../../../components/sortableItem'
import { ApiUpdateServiceCallPriority } from '../../../../graphql/mutation/updateServiceCallPriority'
import { useNavigate, useParams } from 'react-router-dom'

interface EditMemberPageProps{
    setForceUpdate:React.Dispatch<React.SetStateAction<boolean>>|null
}

interface ItemDataProps{
    id:string
    title:string
    email:string
}

export const EditMemberPage = (props:EditMemberPageProps) => {
    const navigate=useNavigate()
    const [loading,setLoading]=React.useState(false)
    const [itemData,setItemData]=React.useState<ItemDataProps[]>([])
    const { serviceId } = useParams<{ serviceId: string }>();
    
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
    );


    const openFailedNotification = (description:string) => {
        notification.error({
          message: 'Error',
          description: `${description}`,
        });
    }

    useEffect(()=>{
        handleGetMemberData()
    },[])
    
    const handleGetMemberData=()=>{
        setLoading(true)
        ApiGetServiceByID(serviceId||'')
        .then((res:any)=>{
            if(res.getServiceByID!==null){
                const members=res.getServiceByID.serviceUsers.sort((a:any,b:any)=>a.callPriority-b.callPriority)
                const data:ItemDataProps[]=[]
                members.map((item:any)=>{
                    data.push({
                        id:item.id,
                        title:item.user.name,
                        email:item.user.description
                    })
                })
                setItemData(data)
            }
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    const handleUpdateServiceCallPriority=(dataList:ItemDataProps[])=>{
        const input=dataList.map((item,index)=>({serviceUserID:item.id,callPriority:index}))
        ApiUpdateServiceCallPriority(serviceId||'',input)
        .then((res:any)=>{
            if(res.updateServiceCallPriority===true){
                if(props.setForceUpdate!==null){
                    props.setForceUpdate((prev)=>!prev)
                }
                navigate("/services/"+serviceId+'/member')
            }
            else{
                openFailedNotification("Failed to update data")
            }
        })
        .catch((err:any)=>{
            openFailedNotification("Failed to update data")
        })
    }

    const reorder = (
        list: Iterable<ItemDataProps> | ArrayLike<ItemDataProps>,
        startIndex: number,
        endIndex: number
      ) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
      
        return result
    }

    const onDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;
        const oldIndex = itemData.findIndex(item => item.id === active.id);
        const newIndex = itemData.findIndex(item => item.id === over.id);
        const newList = reorder(itemData, oldIndex, newIndex);
        setItemData([...newList])
    }

    

  return (
    <>
        <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
        <PageLayout 
            header={
                <NavBar backIcon={<LeftOutline color='white' fontSize={18} style={{fontWeight:900}}/>} 
                    onBack={()=>{
                        navigate("/services/"+serviceId+'/member')
                    }}
                    right={
                        <div style={{fontSize:'14px',color:'white',fontWeight:600,cursor:'pointer'}} onClick={(()=>{handleUpdateServiceCallPriority(itemData)})}>
                            SAVE
                        </div>
                    }
                    style={{width:'100vw'}}>
                        <div style={{fontSize:'18px',color:'white',textAlign:'center',fontWeight:'800'}}>
                            Member
                        </div>
                </NavBar>
            }
            content={
                <>
                    <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd}>
                        <SortableContext items={itemData} strategy={verticalListSortingStrategy}>
                            <List header={
                                <div style={{fontSize:'20px',color:'#75BD76',fontWeight:'400'}}>
                                    Please long press on the item to reorder
                                </div>
                            }>
                            {
                                itemData.map((item:ItemDataProps,index:number)=>(
                                    <SortableItem index={index} item={item}/>
                                ))
                            }
                            </List>
                        </SortableContext>
                    </DndContext>
                    <ConfigProvider
                        theme={{
                            token: {
                                // Seed Token，影响范围大
                                colorPrimary: '#75BD76',
                            },
                        }}
                    >
                        <FloatButton icon={<PlusOutlined />} type='primary' style={{ insetInlineEnd: 24 }} 
                        onClick={()=>{
                            navigate("/services/"+serviceId+'/member/newMember')
                        }}/>
                    </ConfigProvider>
                </>
            }
        />
    </>
  )
}
