// src/fcm.ts
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAA6RZSJddMTMkf6LQ3qzg8r3sLnVBKRw8",
    authDomain: "react-native-iot-smart-sq.firebaseapp.com",
    projectId: "react-native-iot-smart-sq",
    storageBucket: "react-native-iot-smart-sq.firebasestorage.app",
    messagingSenderId: "44322378094",
    appId: "1:44322378094:web:567c980ba7349d146b317f",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const VAPID_KEY = 'BKT0NFU1csB1machceP04JAWP1SIeSYNPDtzFnTPkBv9J1zHTxEW7ryzKPAMIyPgQdcrjgUaJeigKPY2KCkL8oE';


export const requestFcmToken = async (vapidKey: string) => {
  try {
    const token = await getToken(messaging, { vapidKey });
    if (token) {
      console.log('fcmjs FCM Token:', token);
    } else {
      console.warn('No registration token available.');
    }
    return token;
  } catch (error) {
    console.error('An error occurred while retrieving token:', error);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
