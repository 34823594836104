import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { SERVER_TOKEN } from "../../idb"


export const ApiSetSecureOnOff=async(id:string,on:boolean,force?:boolean)=>{
    const mutationStr=gql`
        mutation ApiSetSecureOnOff($id:String!,$on:Boolean!,$force:Boolean){
            setSecureOnOff(id:$id,on:$on,force:$force)
        }
    `
    const variables={
        id: id,
        on: on,
        force: force,
    }
    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)

    try {
        const res = await makeGraphQLRequest<{ setSecureOnOff: boolean }>(mutationStr, variables,applicationClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to set Secure OnOff");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while setting Secure OnOff");
    }

}