import { List,Image } from "antd-mobile"
import moment from "moment"

interface DisconnectedDeviceItemProps{
    devices:DeviceProps[]
}

export interface DeviceProps{
    name:string
    lastReportTime:string
    batteryLevel:number
    firmwareVersion:string
}

export const DisconnectedDeviceItem= (props:DisconnectedDeviceItemProps) => {
    const imgUrlPrefix='../../device/image'

    return (
        <List header={<div style={{color:"black",fontSize:'10px'}}>Disconnected</div>} style={{background:'#F0F0F0'}}>
            {props.devices.map((item:DeviceProps) => (
                <List.Item
                    key={item.name}
                    prefix={<Image src={`${imgUrlPrefix}/link-gray.png`} style={{width:'30px',height:'30px',marginLeft:'5px'}}/>}
                    description={
                        <div style={{marginLeft:'10px',fontSize:'12px'}}>
                            {`Last Callback: ${moment(item.lastReportTime).fromNow()}`}<br/>
                            {`Version: ${item.firmwareVersion}`}
                        </div>
                    }
                >
                    <div style={{display:'flex',marginLeft:'10px',fontSize:'15px'}}>
                        <span style={{marginRight:'8px'}}>{item.name}</span>
                    </div>
                </List.Item>
            ))}
        </List>
    )
}