import { Button, Form, Input } from 'antd-mobile'
import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { ApiCreateUser } from '../../../graphql/mutation/createUser'
import { Modal, Spin, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const LoginText=styled.div`
    display:flex;
    justify-content:center;
    margin-top:15px;
`
const FormContainer=styled.div`
    margin-top: 30px;
    padding: 0 10px;
    width: 100%;
`

interface RegisterInput{
    name:string,
    email:string,
    password:string,
    passwordConfirm:string,
    telephone?:string
}

const defaultInput: RegisterInput = {
    name:'',
    email:'',
    password:'',
    passwordConfirm:'',
    telephone:''
}

export const RegisterPage = () => {
    const emailRegex=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const navigate=useNavigate()
    const [input, setInput] = React.useState<RegisterInput>(defaultInput)
    const [modalVisible,setModalVisible]=React.useState<{status:boolean,open:boolean}>({status:false,open:false})// status: true=>edit failed, false=>edit success
    const [btnDisabled,setBtnDisalbed]=React.useState(true)
    const [loading,setLoading]=React.useState(false)

    useEffect(()=>{
        checkBtnDisabled()
    },[input])

    const handleRegister=()=>{
        setLoading(true)
        ApiCreateUser(input)
        .then((res)=>{
            if(res.createUser!==null){
                setModalVisible({status:false,open:true})
            }
            else{
                setModalVisible({status:true,open:true})
            }
        })
        .catch((err)=>{
            setModalVisible({status:true,open:true})
        })
        .finally(()=>{ 
            setLoading(false)
        })
    }

    const checkBtnDisabled=()=>{
        if(input.name!==""&&emailRegex.test(input.email)&&input.password.length>5&&input.password===input.passwordConfirm){
            setBtnDisalbed(false)
        }
        else{
            setBtnDisalbed(true)
        }
    }

  return (
    <FormContainer>
        <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
        <Form >
            <Form.Item label='Name' name='name' rules={[{ required: true,message:'Required Item'}]}>
                <Input clearable value={input.name} onChange={(val:string)=>{setInput({...input,name:val})}}/>
            </Form.Item>
            <Form.Item label='Account(Email)' name='account' rules={[{ required: true,message:'Required Item'},{ pattern:RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),message: 'Invalid email format'}]}>
                <Input clearable value={input.email} onChange={(val:string)=>{setInput({...input,email:val})}}/>
            </Form.Item>
            <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Required Item'},{type:"string",min:6,message:"Length of password must be greater or equal 6 characters"}]}>
                <Input clearable type='password' value={input.password} onChange={(val:string)=>{setInput({...input,password:val})}}/>
            </Form.Item>
            <Form.Item label='Please enter password again' name='password confirm' rules={[{ required: true,message:'Passwords do not match'},{pattern:RegExp(`^${input.password}$`),message:'Passwords do not match'}]}>
                <Input clearable type='password' value={input.passwordConfirm} onChange={(val:string)=>{setInput({...input,passwordConfirm:val})}}/>
            </Form.Item>
        </Form>
        <Button block color='primary' size='middle' className='colorPrimary' disabled={btnDisabled}
            onClick={()=>{
                handleRegister() 
            }}>
            CREATE ACCOUNT
        </Button>
        <LoginText>
            <span style={{fontSize:'16px'}}>Have Account?
            <a href="/login" style={{fontSize:'16px',margin:'15px 20px',border:'none',textDecoration:'none',color:'#75BD76'}}>Login</a></span>
        </LoginText>

        <Modal
            title={ 
            <>
                {
                    modalVisible.status===true?
                    <>
                        <CloseCircleOutlined style={{color:'red',paddingRight:'10px'}}/>
                        <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                            Create failed
                        </Typography.Text>
                    </>
                    :
                    <>
                        <CheckCircleOutlined style={{color:'green',paddingRight:'10px'}}/>
                        <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                            Create success
                        </Typography.Text>
                    </>
                }
            </>
            }
            style={{top:"30%",height:'55px',zIndex:99}}
            width={'80%'}
            visible={modalVisible.open}
            onCancel={()=>{setModalVisible({...modalVisible,open:false})}}
            maskClosable={false}
            closable={false}
            footer={
                <Button color="primary" size='small' className='colorPrimary' 
                    onClick={()=>{
                        setModalVisible({...modalVisible,open:false})
                        if(modalVisible.status===false){
                            navigate('/login') 
                        }
                    }}>Confirm</Button>
            }
        >
        </Modal>
    </FormContainer>
  )
}
