import { NavBar } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'

export const PageNavbar = (props:{title:string,backTo:string,func?:() => void}) => {
  const navigate=useNavigate()

  return (
      <NavBar backIcon={<LeftOutline color='white' fontSize={18} style={{fontWeight:800}}/>} 
        onBack={()=>{
          navigate(props.backTo)
          if(props.func){
            props.func()
          }
        }} 
        style={{width:'100vw'}}>
          <div style={{fontSize:'18px',color:'white',textAlign:'center',fontWeight:'800'}}>
              {props.title}
          </div>
      </NavBar>
  )
}
