import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { PlatformUser } from "../model/auth/user"
import { SERVER_TOKEN } from "../../idb"

export interface applicationUser{
    id:string
    name:string
    email:string
    emergencyNo:string
    services:[{
        id:string
        name:string
    }]
}

export const ApiGetCurrentUserData=async()=>{
    const queryStr=gql`
        query ApiGetCurrentUserData{
            getCurrentUserData {
                id
                name
                description
                emergencyNo
                roleID
                services {
                    id
                    name
                    enabled
                    site {
                        id
                        name
                    }
                }
                adminServices {
                    id
                    name
                }
                # sites{
                #     id
                #     name
                # }
                # adminSites{
                #     id
                #     name
                # }

            }
        }
    `

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getCurrentUserData:applicationUser}>(queryStr,{},applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to check password reset code");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while checking password reset code");
    }

}