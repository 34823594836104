import { Button, Form, Input } from 'antd-mobile'
import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { ACCOUNT_EMAIL } from '../../../idb'

const SubTitle=styled.div`
    color:#ebac53;
    font-size:18px;
    font-weight:800;
    text-align:center;
    margin-top:-5px;
`

const FormContainer=styled.div`
    margin-top: 30px;
    padding: 0 10px;
    width: 100%;
`

const LoginText=styled.div`
    display:flex;
    justify-content:center;
    margin-top:15px;
`

export const ForgotPasswordPage= () => {
    const location=useLocation()
    const navigate=useNavigate()
    const emailRegex=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const accountEmail=localStorage.getItem(ACCOUNT_EMAIL)||""
    const [input, setInput] = React.useState<{account:string}>({account:accountEmail||''})
    const [btnDisabled,setBtnDisabled]=React.useState(true)

    useEffect(()=>{
        checkBtnDisabled()
    },[input])
    

    const checkBtnDisabled=()=>{
        if(input.account!==""&&emailRegex.test(input.account)){
            setBtnDisabled(false)
        }
        else{
            setBtnDisabled(true)
        }
    }

  return (
    <>
        {
            location.pathname === '/login/forgotPassword' &&
            <>
                <SubTitle>Request For Editing Password</SubTitle>
                <FormContainer>
                    <Form layout='horizontal' style={{marginBottom:'15px'}}>
                        <Form.Item label='' name='account' rules={[{ required: true, message: 'Required Item'},{ pattern:RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),message: 'Invalid email format'}]}>
                            <Input placeholder='Confirm Account' clearable defaultValue={accountEmail} value={input.account} onChange={(val:string)=>{setInput({...input,account:val})}}/>
                        </Form.Item>
                    </Form>
                    <Button block color='primary' size='middle' className='colorPrimary'
                        disabled={btnDisabled}
                        onClick={()=>{
                            navigate(`/login/forgotPassword/setRequest?account=${input.account}`)
                        }}>
                        SEND REQUEST
                    </Button>
                    <LoginText>
                        <span style={{fontSize:'16px'}}>Cancel Request?
                        <a href="/login" style={{fontSize:'16px',margin:'15px 20px',border:'none',textDecoration:'none',color:'#75BD76'}}>Login</a></span>
                    </LoginText>
                </FormContainer>
            </>
        }
    </>
  )
}
