import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest,  } from "../url"
import { SERVER_TOKEN } from "../../idb"


export const ApiHasSecureAlarmEvents=async(serviceId:string)=>{
    const queryStr=gql`
        query ApiHasSecureAlarmEvents($serviceId:String!){
            hasSecureAlarmEvents(serviceID:$serviceId)
        }
    `
    const variables={
        serviceId:serviceId,
    }
    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{hasSecureAlarmEvents:boolean}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get SecureAlarmEvents");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting SecureAlarmEvents");
    }

}