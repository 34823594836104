import { ConfigProvider,FloatButton, notification, Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBatteryQuarter, faHandPointer, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { PageLayout } from '../../components/pageLayout'
import { PageNavbar } from '../../components/pageNavbar'
import { List } from 'antd-mobile'
import moment from 'moment'
import { styled } from 'styled-components'
import { ApiGetSitePrompts } from '../../../graphql/query/getPrompt'
import { Prompt } from '../../../graphql/model/application/prompt'
import React from 'react'
import { SyncOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'

const CardContainer=styled.div`
  padding: 10px 10px 5px 10px;
`




export const ReminderPage = () => {
  const [loading, setLoading] = useState(false)
  const [itemData,setItemData]=React.useState<Prompt[]>([])
  const {serviceId} = useParams<{ serviceId: string }>()

  useEffect(()=>{
    handleGetSitePromps()
  },[])

  const openFailedNotification = (description:string) => {
    notification.error({
      message: 'Error',
      description: `${description}`,
    });
  }

  const handleGetSitePromps=()=>{
        setLoading(true)
        
        ApiGetSitePrompts(serviceId||"").then((res)=>{
          if(res.getSitePrompts.length>0){
            //console.log("res.getSitePrompts",res.getSitePrompts)

            setItemData(res.getSitePrompts)
          }
          
        })
        .catch((err:any)=>{
          openFailedNotification("Failed to get data"+err)
        })
        .finally(()=>{
          setLoading(false)
        })
    }

  return ( 
      <>
          <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
          <PageLayout 
            header={
              <PageNavbar title="Reminder" backTo={"/services/"+serviceId}/>
            }
            content={           
              <>
              <List>
                {
                  itemData.map((item:Prompt,index:number)=>(
                    <List.Item
                      key={item.id}
                      style={{ paddingRight:'20px' }}
                      prefix={
                        item.action === "ENABLE" ? <FontAwesomeIcon icon={faHandPointer} fontSize={24} color='#EBAC51' /> :
                        item.action === "DISABLE" ? <FontAwesomeIcon icon={faTriangleExclamation} fontSize={24} color='#dc3545'/> :
                        item.action === "BATTERY" ? <FontAwesomeIcon icon={faBatteryQuarter} fontSize={24} color='#dc3545'/> : <></>
                      }
                     
                      extra={
                        <div style={{textAlign:'center'}}>
                          {moment(`${item.checkTime}`).format('MM-DD')}<br/>
                          {moment(`${item.checkTime}`).format('a h:mm')}
                        </div>
                      }
                    >
                      {item.message}
                    </List.Item>
                  ))
                }
              </List>
              <ConfigProvider
                  theme={{
                      token: {
                          // Seed Token，影响范围大
                          colorPrimary: '#75BD76',
                      },
                  }}
                >
                  <FloatButton icon={<SyncOutlined />} type='primary' style={{ insetInlineEnd: 24 }} 
                  onClick={()=>{
                      handleGetSitePromps()
                    }}/>
              </ConfigProvider>
            </>
            }
          />
      </>
  )
}
