import { Modal, Space, Spin, Typography } from 'antd'
import { SearchOutlined,UserAddOutlined } from '@ant-design/icons'
import { LeftOutline } from 'antd-mobile-icons'
import React from 'react'
import { Button, Input, List, NavBar } from 'antd-mobile'
import { styled } from 'styled-components'
import { ApiGetPlatFormUserByEmail } from '../../../../graphql/query/getPlatformUserByEmail'
import { ApiAddUserToService } from '../../../../graphql/mutation/addUserToService'
import { useNavigate, useParams } from 'react-router-dom'

interface NewMemberPageProps{
    setForceUpdate:React.Dispatch<React.SetStateAction<boolean>>|null
}

const Header=styled.header`
    position:fixed;
    z-index:100;
    background:#75BD76;
    height:100px;
    width:100%;
`
const Main=styled.main`
    padding-top:100px;
`

interface ItemDataProps{
    id:string
    name:string
    email:string
}

export const NewMemberPage = (props:NewMemberPageProps) => {
    const navigate=useNavigate()
    const [loading, setLoading] = React.useState(false)
    const [input,setInput]=React.useState('')
    const [itemData,setItemData]=React.useState<ItemDataProps[]>([])
    const [itemEmpty,setItemEmpty]=React.useState("Please enter email to search")
    const [confirmModalVisible, setConfirmModalVisible] = React.useState<{id:string,open:boolean}>({id:"",open:false})
    const { serviceId } = useParams<{ serviceId: string }>();
    
  const handleGetPlatformUserByEmail=()=>{
    setLoading(true)
    ApiGetPlatFormUserByEmail(input)
    .then((res:any)=>{
        if(res.getPlatformUserByEmail!==null){
            setItemData([{id:res.getPlatformUserByEmail.id,name:res.getPlatformUserByEmail.name,email:res.getPlatformUserByEmail.email}])
        }
        else{
            setItemData([])
            setItemEmpty("No Data")
        }
    })
    .catch((err:any)=>{
        setItemData([])
        setItemEmpty("No Data")
    })
    .finally(()=>{
        setLoading(false)
    })
  }

  const handleAddMember=(userId:string)=>{
    setLoading(true)
    ApiAddUserToService(userId,serviceId||'')
    .then((res:any)=>{
        setConfirmModalVisible({id:"",open:false})
        if(res.addUserToService===true){
            if(props.setForceUpdate!==null){
                props.setForceUpdate(prev=>!prev)
            }
            navigate("/services/"+serviceId+'/member')
        }
        else{
            if(props.setForceUpdate!==null){
                props.setForceUpdate(prev=>!prev)
            }
            navigate("/services/"+serviceId+'/member')
        }
    })
    .catch((err:any)=>{
        navigate("/services/"+serviceId+'/member')
    })
    .finally(()=>{
        setLoading(false)
    })
  }


  return (
      <>
          <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
          <section>
            <Header>
                <section style={{width:'100%'}}>
                    <NavBar backIcon={<LeftOutline color='white' fontSize={18} style={{fontWeight:800}}/>} 
                        onBack={()=>{
                            navigate("/services/"+serviceId+'/member')
                        }}
                        style={{width:'100vw'}}>
                        <div style={{fontSize:'18px',color:'white',textAlign:'center',fontWeight:'800'}}>
                            New Member
                        </div>
                    </NavBar>
                </section>
                <section style={{display:'flex',width:'100%',padding:'0 0 5px 8px',marginTop:'10px'}}>
                    <Input
                        style={{backgroundColor:'white',padding:'5px',width:'90%'}}
                        placeholder='Account(Email)'
                        value={input}
                        onChange={val => {
                            setInput(val)
                        }}
                        />
                    <SearchOutlined style={{color:'white',fontWeight:'800',fontSize:'24px',marginLeft:'5px'}} onClick={()=>{handleGetPlatformUserByEmail()}}/>
                </section>
            </Header>
            <Main>
                <>
                    {
                        itemData.length===0?
                        <Typography.Title level={4} style={{color:'#75BD76',textAlign:'center',marginTop:'50px'}}>{itemEmpty}</Typography.Title>
                        :
                        <List>
                        {
                            itemData.map((item:ItemDataProps)=>(
                            <List.Item
                                key={item.id}
                                description={item.email}
                                extra={<UserAddOutlined style={{color:'#75BD76',fontSize:'24px'}} onClick={()=>{
                                    setConfirmModalVisible({id:item.id,open:true})
                                }}/>}
                            >
                                {item.name}
                            </List.Item>
                            ))
                        }
                        </List>

                    }
                </>
                <Modal
                    title={ 
                    <>
                        <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                            Confirm Add to Site
                        </Typography.Text>
                    </>
                    }
                    style={{top:"30%",height:'55px',zIndex:99}}
                    width={'80%'}
                    open={confirmModalVisible.open}
                    onCancel={()=>{setConfirmModalVisible({id:"",open:false})}}
                    maskClosable={false}
                    closable={false}
                    footer={
                        <Space>
                            <Button color="primary" size='small' className='colorPrimary' 
                                onClick={()=>{
                                    setConfirmModalVisible({id:"",open:false})
                                }}>CANCEL</Button>
                            <Button color="primary" size='small' className='colorPrimary' 
                                onClick={()=>{
                                    handleAddMember(confirmModalVisible.id)
                                }}>CONFIRM</Button>
                        </Space>
                    }
                >
                </Modal>
            </Main>
        </section>
      </>
  )
}
