import React, { useEffect } from 'react'
import { PageNavbar } from '../components/pageNavbar'
import { PageLayout } from '../components/pageLayout'
import { List } from 'antd-mobile'
import {SignatureOutlined,UserOutlined,EditOutlined,LoginOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { EditPasswordPage } from './editPassword'
import { EditPermissionsPage } from './editPermissions'
import { EditBasicInfoPage } from './editBasicInfo'
import { ApiGetCurrentUserData } from '../../graphql/query/getCurrentUserData'
import { ServiceProps } from '../home/navbar'
import { notification, Spin } from 'antd'
import { ACCOUNT_EMAIL, FCM_TOKEN, SERVER_TOKEN } from '../../idb'
import { ApiDisconnectFromFcm } from '../../graphql/mutation/disconnectFromFcm'


const PrefixIcon=styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background:#ebac53;
    width:26px;
    height:26px;
    border-radius:4px;
`

export const SettingsPage = () => {
    const location = useLocation()
    const [loading, setLoading] = React.useState(false)
    const navigate=useNavigate()
    const [permissionsItemVisible, setPermissionsItemVisible] = React.useState(true)
    const { serviceId } = useParams<{ serviceId: string }>();

    useEffect(()=>{
        handleGetCurrentUserData()
    },[])

    const resetLocalStorage = () => {
        localStorage.removeItem(SERVER_TOKEN);
        localStorage.removeItem(ACCOUNT_EMAIL);
    }

    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: `${description}`,
        });
    }
    
    const handleGetCurrentUserData=()=>{
        ApiGetCurrentUserData()
        .then((res:any)=>{
            if(res.getCurrentUserData!==null){
                if(res.getCurrentUserData.adminServices.length===0){//非管理員
                    setPermissionsItemVisible(false)
                }
                else if(res.getCurrentUserData.adminServices.length>0){//管理員=>判斷是否在當前的service有權限
                    res.getCurrentUserData.adminServices.map((item:ServiceProps)=>{
                        if(item.id===serviceId){
                            setPermissionsItemVisible(true)
                        }
                    })
                }
            }
            else{
                openFailedNotification("Failed to get data")
            }
        })
        .catch((error:any)=>{
            openFailedNotification("Failed to get data")
        })
    }

  return (
    <>
    {
        <PageLayout 
            header={
                <PageNavbar title="Setting" backTo={"/services/"+serviceId}/>
            }
            content={
                <>
                    <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
                    <List header='Personal Information'>
                        <List.Item prefix={<PrefixIcon><SignatureOutlined style={{fontSize:'20',color:'white'}}/></PrefixIcon>} onClick={() => {navigate("/services/"+serviceId+"/sys-settings/editPassword")}} arrowIcon={false}>
                            Edit Password
                        </List.Item>
                        <List.Item prefix={<PrefixIcon><UserOutlined style={{fontSize:'20',color:'white'}}/></PrefixIcon>} onClick={() => {navigate("/services/"+serviceId+"/sys-settings/editBasicInfo")}} arrowIcon={false}>
                            Edit Basic Info
                        </List.Item>
                        {
                            permissionsItemVisible===true&&
                            <List.Item prefix={<PrefixIcon><EditOutlined style={{fontSize:'20',color:'white'}}/></PrefixIcon>} onClick={() => {navigate("/services/"+serviceId+"/sys-settings/editPermissions")}} arrowIcon={false}>
                                Edit Permissions
                            </List.Item>
                        }
                        <List.Item prefix={<PrefixIcon><LoginOutlined style={{fontSize:'20',color:'white'}}/></PrefixIcon>} 
                            onClick={async () => { 
                                
                                // disconnect from FCM
                                const fcmToken = localStorage.getItem(FCM_TOKEN)
                                const serverToken = localStorage.getItem(SERVER_TOKEN)

                                if(fcmToken && serverToken){
                                    setLoading(true)
                                    ApiDisconnectFromFcm(fcmToken,serverToken).catch((err)=>{
                                        notification.error({
                                            message: 'Error',
                                            description: 'Failed to disconnect from FCM',
                                        });    
                                    }).finally(()=>{
                                        setLoading(false)
                                    })
                                }else{
                                    notification.error({
                                        message: 'Error',
                                        description: 'fcmToken or serverToken not found',
                                    })
                                    console.error('fcmToken or serverToken not found')
                                }
                                resetLocalStorage()
                                navigate("/login")
                            }} 
                            arrowIcon={false}>
                            Logout
                        </List.Item>
                    </List>
                </>

            }
        />
    }
    {
        location.pathname === "/services/"+serviceId+'/sys-settings/editPassword'&&
        <EditPasswordPage/>
    }
    {
        location.pathname === "/services/"+serviceId+'/sys-settings/editBasicInfo'&&
        <EditBasicInfoPage/>
    }
    {
        location.pathname === "/services/"+serviceId+'/sys-settings/editPermissions'&&
        <EditPermissionsPage/>
    }
    {
        location.pathname === "/services/"+serviceId+'/sys-settings/editPermissions/permissions'&&
        <EditPermissionsPage/>
    }
    </>
  )
}
