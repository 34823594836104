import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { UserInfoInput } from "../model/application/userInfoInput"
import { SERVER_TOKEN } from "../../idb"

export const ApiUpdateUserInfo=async(input:UserInfoInput)=>{
    const mutationStr=gql`
        mutation ApiUpdateUserInfo($input:UserInfoInput!){
            updateUserInfo(input:$input)
        }
    `
    const variables={
        input:input
    }
    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)

    try {
        const res = await makeGraphQLRequest<{ updateUserInfo: boolean }>(mutationStr, variables,applicationClient);
        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to update user info");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while updating user info");
    }
}