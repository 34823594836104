import { gql } from "graphql-request"
import { authClient, makeGraphQLRequest } from "../url"


export const ApiResetPassword=async(email:string,code:string,newPassword:string)=>{
    const mutationStr=gql`
        mutation ApiResetPassword($email:String!,$code:String!,$newPassword:String!){	
            resetPassword(email:$email,code:$code,newPassword:$newPassword)
        }
    `
    const variables={
        email:email,
        code:code,
        newPassword:newPassword
    }

    try {
        const res = await makeGraphQLRequest<{ resetPassword: boolean }>(mutationStr, variables,authClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to reset password");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while reseting the password");
    }

}