import { Modal, Space, Spin, Typography } from 'antd'
import { Button } from 'antd-mobile'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface ForceEnableSecureSystemModalProps {
    visible:boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    loading:boolean
    handleSetSecureOnOffFunction:(secureOnOff:boolean,force:boolean)=>void
}

export const ForceEnableSecureSystemModal = (props:ForceEnableSecureSystemModalProps) => {
    const navigate=useNavigate()
    const { serviceId } = useParams<{ serviceId: string }>();

  return (
    <>
        <Modal
            title={ 
                <>
                    <Spin spinning={props.loading} tip="Loading" size="large" className='modalLoading'>
                        <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                            有裝置異常警報，您確定要強制開啟保全？
                        </Typography.Text>
                    </Spin>
                </>
            }
            style={{top:"30%",height:'55px',zIndex:99}}
            width={'80%'}
            open={props.visible}
            onCancel={()=>{props.setVisible(false)}}
            maskClosable={false}
            closable={false}
            footer={
                <>
                <Space>
                    <Button color="primary" size='small' className='colorPrimary' disabled={props.loading}
                        onClick={()=>{
                            props.setVisible(false)
                            navigate("/services/"+serviceId+"/device")
                        }}>VIEW DEVICE</Button>
                    <Button color="primary" size='small' className='colorPrimary' disabled={props.loading}
                        onClick={()=>{
                            props.handleSetSecureOnOffFunction(true,true)
                        }}>CONFIRM</Button>
                    <Button color="primary" size='small' className='colorPrimary' disabled={props.loading}
                        onClick={()=>{
                            props.setVisible(false)
                        }}>CANCEL</Button>
                </Space>
                </>
            }
        >
        </Modal>
    </>
  )
}
