import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { NumetUser } from "../model/application/numetUser"
import { SERVER_TOKEN } from "../../idb"


export const ApiGetPlatFormUserByEmail=async(email:string)=>{
    const queryStr=gql`
        query ApiGetPlatFormUserByEmail($email:String!){
            getPlatformUserByEmail(email:$email){
                id
                name
                email
            }
        }
    `
    const variables={
        email:email
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getPlatformUserByEmail:NumetUser}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get Platform Users");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting Platform Users");
    }

}