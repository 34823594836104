import { Modal, Typography } from 'antd'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Form, Input,Image, Button } from 'antd-mobile'
import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ApiResetPassword } from '../../../../graphql/mutation/resetPassword'

const SubTitle=styled.div`
    color:#ebac53;
    font-size:18px;
    font-weight:800;
    text-align:center;
    margin-top:-3px;
`

const FormContainer=styled.div`
    margin-top: 40px;
    padding: 0 10px;
    width: 100%;
`

export const EnterPasswordPage = () => {
    const imageUrlPrefix = '../../home/image'
    const [input,setInput]=React.useState<{password:string,passwordConfirm:string}>({password:'',passwordConfirm:''})
    const [btnDisabled,setBtnDisabled]=React.useState(true)
    const [modalVisible, setModalVisible] = React.useState<{status:boolean,open:boolean}>({status:false,open:false})// status: true=>edit failed, false=>edit success
    const navigate=useNavigate()
    const [getParams] = useSearchParams()
    const queryAccount=getParams.get('account')||""
    const queryCode=getParams.get('code')||""

    useEffect(()=>{
        checkBtnDisabled()
    },[input])

    const handleResetPassword=()=>{
        ApiResetPassword(queryAccount,queryCode,input.password)
        .then((res)=>{
            if(res.resetPassword===true){
                setModalVisible({status:false,open:true})
            }
            else{
                setModalVisible({status:true,open:true})
            }
        })
        .catch((err)=>{
            setModalVisible({status:true,open:true})
        })
    }

    const checkBtnDisabled=()=>{
        if(input.password!==""&&input.password.length>5&&input.passwordConfirm!==""&&input.password===input.passwordConfirm){
            setBtnDisabled(false)
        }
        else{
            setBtnDisabled(true)
        }
    }

  return (
    <>
        <Image src={`${imageUrlPrefix}/logo.png`} width={150} height={150} alt='button' fit='fill' />
        <SubTitle>Reset Password</SubTitle>
        <FormContainer>
            <Form style={{marginBottom:'15px'}}>
                <Form.Item label='New Password' name='password' rules={[{ required: true, message: 'Required Item'},{type:"string",min:6,message:"Length of password must be greater or equal 6 characters"}]}>
                    <Input clearable type='password' value={input.password} onChange={(val:string)=>{setInput({...input,password:val})}}/>
                </Form.Item>
                <Form.Item label='Please enter password again' name='password confirm' rules={[{ required: true,message:'Passwords do not match'},{pattern:RegExp(`^${input.password}$`),message:'Passwords do not match'}]}>
                    <Input clearable type='password' value={input.passwordConfirm} onChange={(val:string)=>{setInput({...input,passwordConfirm:val})}}/>
                </Form.Item>
            </Form>
            <Button block color='primary' size='middle' className='colorPrimary'
                disabled={btnDisabled}
                onClick={()=>{
                    handleResetPassword()
                }}>
                RESET PASSWORD
            </Button>
        </FormContainer>

        <Modal
            title={ 
            <>
                {
                    modalVisible.status===true?
                    <>
                        <CloseCircleOutlined style={{color:'red',paddingRight:'10px'}}/>
                        <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                            Reset password failed
                        </Typography.Text>
                    </>
                    :
                    <>
                        <CheckCircleOutlined style={{color:'green',paddingRight:'10px'}}/>
                        <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                            Reset password successful
                        </Typography.Text>
                    </>
                }
            </>
            }
            style={{top:"30%",height:'55px',zIndex:99}}
            width={'80%'}
            open={modalVisible.open}
            onCancel={()=>{setModalVisible({...modalVisible,open:false})}}
            maskClosable={false}
            closable={false}
            footer={
                <Button color="primary" size='small' className='colorPrimary' 
                    onClick={()=>{
                        setModalVisible({...modalVisible,open:false})
                        navigate('/login')  
                    }}>Confirm</Button>
            }
        >
            {
                modalVisible.status===true
                &&
                <Typography.Text strong style={{fontWeight:'600'}}>
                    Reset code not found or time limit expired or password change already made: record not found
                </Typography.Text>
            }
        </Modal>
    </>
  )
}
