import { GraphQLClient } from "graphql-request";

const authHost = "asia-01.prod.numet.ai";
const authPort = "9475";
// const authHost = "asia-01.prod.numet.ai";
// const authPort = "9475";
// -----------------------------------
// const applicationHost = "localhost";
// const applicationPort = "8080";
const applicationHost = "asia-01.prod.numet.ai";
const applicationPort = "9482";

export const authUrl = "https://" + authHost + ":" + authPort;
export const authClient = new GraphQLClient(authUrl + "/graphql")

// export const applicationUrl = "http://" + applicationHost + ":"+applicationPort;
export const applicationUrl = "https://" + applicationHost + ":"+applicationPort;
export const applicationClient = new GraphQLClient(applicationUrl + "/graphql")

export async function makeGraphQLRequest<T>(query:string,variables:any,client:GraphQLClient){
    try{
        const res=await client.request<T>(query,variables);
        return res
    }
    catch(err:any){
        throw Error(err)
    }
}