import { Grid } from 'antd-mobile'
import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import FunctionButton from '../../../components/functionButton'
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiGetUserServiceFunctions } from '../../../../graphql/query/getUserServiceFunction';
import { notification, Spin } from 'antd';
import { FunctionInput } from '../../../../graphql/model/application/functionInput';
import { ApiUpdateUserServiceFunctions } from '../../../../graphql/mutation/updateUserServiceFunctions';
import { PageLayout } from '../../../components/pageLayout';
import { PageNavbar } from '../../../components/pageNavbar';

export interface PermissionsItemProps{
    secure:boolean;
    secureOnOff?:boolean;
    camera:boolean;
    reminder:boolean;
    events:boolean;
    device:boolean;
    deviceAlarm?:number;
    member:boolean;
    deviceGroup:boolean;
    ruleEngine:boolean;
    smartConfig:boolean;
    cameraConfig:boolean;
    deviceToNVR:boolean;
    unlockAlarm:boolean;
    unlockAlarmStatus?:boolean;
}

export const defaultPermissionsItem:PermissionsItemProps={
    secure:true,
    camera:true,
    reminder:true,
    events:true,
    device:true,
    deviceAlarm:0,
    member:true,
    deviceGroup:true,
    ruleEngine:true,
    smartConfig:true,
    cameraConfig:true,
    deviceToNVR:true,
    unlockAlarm:true,
    unlockAlarmStatus:true,
}

const GridContainer=styled(Grid)`
    padding:10px 10px;
`

export const PermissionsPage = () => {
    const [editPermissions, setEditPermissions] = React.useState<PermissionsItemProps>(defaultPermissionsItem)
    const [inputs, setInputs] = React.useState<FunctionInput[]>([])
    const imgUrlPrefix='/settings/editPermissions/permissions/image'
    const [loading, setLoading] = React.useState(false)
    const [getParams] = useSearchParams()
    const queryId=getParams.get('id')
    const {serviceId} = useParams<{ serviceId: string }>()

    useEffect(()=>{
        handleGetUserServiceFunctions()
    },[])

    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: `${description}`,
        });
    }

    const handleGetUserServiceFunctions=()=>{
        setLoading(true)
        ApiGetUserServiceFunctions(queryId||"",serviceId||'')
        .then((res:any)=>{
            if(res.getUserServiceFunctions!==null){
                const permissions=res.getUserServiceFunctions
                const initPermissions:PermissionsItemProps={
                    secure:permissions.filter((item:any)=>item.baseFunctionID==="secure.on_off")[0]?.enabled,
                    camera:permissions.filter((item:any)=>item.baseFunctionID==="nvr.view")[0]?.enabled,
                    reminder:permissions.filter((item:any)=>item.baseFunctionID==="secure.reply.view")[0]?.enabled,
                    events:permissions.filter((item:any)=>item.baseFunctionID==="device.event.view")[0]?.enabled,
                    device:permissions.filter((item:any)=>item.baseFunctionID==="device.view")[0]?.enabled,
                    member:permissions.filter((item:any)=>item.baseFunctionID==="user.view")[0]?.enabled,
                    deviceGroup:permissions.filter((item:any)=>item.baseFunctionID==="device.group.edit")[0]?.enabled,
                    ruleEngine:permissions.filter((item:any)=>item.baseFunctionID==="rule.device2device.edit")[0]?.enabled,
                    smartConfig:permissions.filter((item:any)=>item.baseFunctionID==="device.config")[0]?.enabled,
                    cameraConfig:permissions.filter((item:any)=>item.baseFunctionID==="nvr.edit")[0]?.enabled,
                    deviceToNVR:permissions.filter((item:any)=>item.baseFunctionID==="device.map.camera.edit")[0]?.enabled,
                    unlockAlarm:permissions.filter((item:any)=>item.baseFunctionID==="rule.secure.alarm.dismiss")[0]?.enabled,
                }
                setEditPermissions(initPermissions)
            }
            else{
                openFailedNotification("Failed to get data")
            }
        })
        .catch((error:any)=>{
            openFailedNotification("Failed to get data")
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    const handleUpdateUserServiceFunctions=()=>{
        ApiUpdateUserServiceFunctions(queryId||"",serviceId||'',inputs)
        .then((res:any)=>{
            if(res.updateUserServiceFunctions===false){
                openFailedNotification("Failed to update data")
            }
        })
        .catch((error:any)=>{    
            openFailedNotification("Failed to update data")
        })
    }


  return (
    <PageLayout 
        header={
            <PageNavbar title="Edit Permissions" backTo={"/services/"+serviceId+"/sys-settings/editPermissions"} func={handleUpdateUserServiceFunctions}/>
        }
        content={
            <>
                <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
                <GridContainer columns={3} gap={20}>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.secure?`${imgUrlPrefix}/site-on.png`: `${imgUrlPrefix}/muti-sites-off-none-red.png`}} 
                            title='SECURE'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,secure:!editPermissions.secure})
                                setInputs([...inputs,{baseFunctionID:'secure.on_off',enabled:!editPermissions.secure}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.camera?`${imgUrlPrefix}/new-camera.png`: `${imgUrlPrefix}/new-camera-none-red.png`}} 
                            title='Camera'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,camera:!editPermissions.camera})
                                setInputs([...inputs,{baseFunctionID:'nvr.view',enabled:!editPermissions.camera}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.reminder?`${imgUrlPrefix}/notice-reply.png`: `${imgUrlPrefix}/notice-reply-none-red.png`}} 
                            title='Reminder'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,reminder:!editPermissions.reminder})
                                setInputs([...inputs,{baseFunctionID:'secure.reply.view',enabled:!editPermissions.reminder}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.events?`${imgUrlPrefix}/new-events.png`: `${imgUrlPrefix}/new-events-none-red.png`}} 
                            title='Events'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,events:!editPermissions.events})
                                setInputs([...inputs,{baseFunctionID:'device.event.view',enabled:!editPermissions.events}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.device?`${imgUrlPrefix}/new-devices.png`: `${imgUrlPrefix}/new-devices-none-red.png`}} 
                            title='Device'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,device:!editPermissions.device})
                                setInputs([...inputs,{baseFunctionID:'device.view',enabled:!editPermissions.device}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.member?`${imgUrlPrefix}/new-members.png`: `${imgUrlPrefix}/new-members-none-red.png`}} 
                            title='Member'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,member:!editPermissions.member})
                                setInputs([...inputs,{baseFunctionID:'user.view',enabled:!editPermissions.member},{baseFunctionID:'user.edit',enabled:!editPermissions.member}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.deviceGroup===true?`${imgUrlPrefix}/device-group.png`:`${imgUrlPrefix}/device-group-none-red.png`}} 
                            title='Device Group' 
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,deviceGroup:!editPermissions.deviceGroup})
                                setInputs([...inputs,{baseFunctionID:'device.group.edit',enabled:!editPermissions.deviceGroup}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.ruleEngine?`${imgUrlPrefix}/rule-engine.png`:`${imgUrlPrefix}/rule-engine-none-red.png`}} 
                            title='Rule Engine'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,ruleEngine:!editPermissions.ruleEngine})
                                setInputs([...inputs,{baseFunctionID:'rule.device2device.edit',enabled:!editPermissions.ruleEngine},{baseFunctionID:'rule.secure.alarm.edit',enabled:!editPermissions.ruleEngine},{baseFunctionID:'rule.secure.on_off.edit',enabled:!editPermissions.ruleEngine}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.smartConfig?`${imgUrlPrefix}/wifi-boardcast.png`:`${imgUrlPrefix}/wifi-boardcast-none-red.png`}} 
                            title='Smart Config'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,smartConfig:!editPermissions.smartConfig})
                                setInputs([...inputs,{baseFunctionID:'device.config',enabled:!editPermissions.smartConfig}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.cameraConfig?`${imgUrlPrefix}/camera-config.png`: `${imgUrlPrefix}/camera-config-none-red.png`}} 
                            title='Camera Config'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,cameraConfig:!editPermissions.cameraConfig})
                                setInputs([...inputs,{baseFunctionID:'nvr.edit',enabled:!editPermissions.cameraConfig}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.deviceToNVR?`${imgUrlPrefix}/device-to-camera.png`: `${imgUrlPrefix}/device-to-camera-none-red.png`}} 
                            title='Device To NVR'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,deviceToNVR:!editPermissions.deviceToNVR})
                                setInputs([...inputs,{baseFunctionID:'device.map.camera.edit',enabled:!editPermissions.deviceToNVR}])
                            }}/>
                    </Grid.Item>
                    <Grid.Item>
                        <FunctionButton 
                            image={{src:editPermissions.unlockAlarm?`${imgUrlPrefix}/alarm-reset.png`: `${imgUrlPrefix}/alarm-reset-none-red.png`}} 
                            title='Unlock Alarm'
                            clickEvent={()=>{
                                setEditPermissions({...editPermissions,unlockAlarm:!editPermissions.unlockAlarm})
                                setInputs([...inputs,{baseFunctionID:'rule.secure.alarm.dismiss',enabled:!editPermissions.unlockAlarm}])
                            }}/>
                    </Grid.Item>
                </GridContainer>
            </>
        }
    />
  )
}
