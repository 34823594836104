import { gql } from "graphql-request";

import { PlatformUser } from "../model/auth/user";
import { UserInput } from "../model/auth/userInput";
import { authClient, makeGraphQLRequest } from "../url";

export const ApiCreateUser=async(userInput:UserInput)=>{
    const mutationStr=gql`
        mutation ApiCreateUser($input:UserInput!){
            createUser(input:$input){
                id
                email
                name
                telephone
            }
        }
    `
    
    const variables={
        input:{
            name:userInput.name,
            email:userInput.email,
            password:userInput.password,
            telephone:userInput.telephone
        }
    }


    try {
        const res = await makeGraphQLRequest<{ createUser: PlatformUser }>(mutationStr, variables,authClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to register");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while registering");
    }
}

