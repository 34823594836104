import { Modal, Space, Spin, Typography } from 'antd'
import { PermissionsItemProps } from '../../../settings/editPermissions/permissions'
import { Button, Form, Input } from 'antd-mobile'

interface ControlSecureSystemModalProps {
    pageBtnVisible: PermissionsItemProps
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    loading: boolean
    input:string
    setInput:React.Dispatch<React.SetStateAction<string>>
    loginFunction:()=>void
}

export const ControlSecureSystemModal = (props:ControlSecureSystemModalProps) => {
  return (
    <>
        <Modal
            title={ 
            <>
                {
                    props.pageBtnVisible.secureOnOff===true?//enabled==true
                    <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                        Disable SECURE System
                    </Typography.Text>
                    :
                    <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                        Enable SECURE System
                    </Typography.Text>
                }
            </>
            }
            style={{top:"30%",height:'55px',zIndex:99}}
            width={'80%'}
            open={props.visible}
            onCancel={()=>{props.setVisible(false)}}
            maskClosable={false}
            closable={false}
            footer={
                <Space>
                    <Button color="primary" size='small' className='colorPrimary' disabled={props.loading}
                        onClick={()=>{
                            props.setVisible(false)
                        }}>Cancel</Button>
                    <Button color="primary" size='small' className='colorPrimary' disabled={props.loading}
                        onClick={()=>{
                            // handleLogin()
                            props.loginFunction()
                        }}>Confirm</Button>
                </Space>
            }
        >
            <Spin spinning={props.loading} tip="Loading" size="large" className='modalLoading'>
                <Typography.Text strong style={{fontWeight:'600'}}>
                    Please enter password to do action
                </Typography.Text>
                <div style={{padding:'10px 0',marginBottom:'10px'}}>
                    <Form>
                        <Input
                            placeholder='Password for login'
                            value={props.input}
                            style={{fontSize:'14px',padding:'10px 0'}}
                            type='password'
                            onChange={(val) => {
                                props.setInput(val)
                            }}
                        />
                    </Form>
                </div>
            </Spin>
        </Modal>
    </>
  )
}
