import { gql } from "graphql-request"
import { authClient, makeGraphQLRequest } from "../url"


export const ApiSendPasswordResetCode=async(email:string)=>{
    const queryStr=gql`
        query ApiSendPasswordResetCode($email:String!){
            sendPasswordResetCode(email:$email)
        }
    `
    const variables={
        email:email,
    }

    
    try {
        const res=makeGraphQLRequest<{sendPasswordResetCode:boolean}>(queryStr,variables,authClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to send password reset code");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while sending password reset code");
    }

}