import { gql } from "graphql-request"
import { makeGraphQLRequest, applicationClient } from "../url"


export const ApiDisconnectFromFcm=async(deviceToken:string,token:string)=>{
    const mutationStr=gql`
        mutation disconnectFromFcm($deviceToken:String!){
            disconnectFromFcm(deviceToken:$deviceToken)
        }

    `
    const variables={
        deviceToken: deviceToken,
    }
    applicationClient.setHeader("authorization",`Bearer ${token}`)

    try {
        const res = await makeGraphQLRequest<{ changePassword: boolean }>(mutationStr, variables,applicationClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to disconnect from FCM");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while disconnecting from FCM");
    }

}