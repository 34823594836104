import { gql } from "graphql-request"
import { DeviceView } from "../model/application/deviceView"
import { SERVER_TOKEN } from "../../idb"
import { applicationClient, makeGraphQLRequest } from "../url"
import { Device } from "../model/application/device"

export const ApiGetDevicesByView=async(serviceId:string,view:DeviceView)=>{
    const queryStr=gql`
        query ApiGetDevicesByView($serviceId:String!,$view:DeviceView!){
            getDevicesByView(serviceID:$serviceId, view:$view){
                    id
                    name
                    lastReportTime
                    state # status
                    batteryLevel
                    firmwareVersion
                }
            }
    `
    const variables={
        serviceId: serviceId,
        view: view,
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getDevicesByView:Device[]}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get events");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting events");
    }

}