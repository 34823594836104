import { openDB } from "idb"; // 匯入 IndexedDB 工具庫

// 定義資料庫名稱與資料存儲名稱
const DB_NAME = "fcm-database";
const STORE_NAME = "tokens";
export const SERVER_TOKEN = "serverToken";
export const FCM_TOKEN = "fcmToken";
export const SERVICE_ID = "currentServiceId";
export const ACCOUNT_EMAIL = "accountEmail";

// 儲存 FCM Token 到 IndexedDB
export const saveFcmToken = async (token: string) => {
    // 開啟或建立資料庫
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) { // 資料庫升級時執行
            // 如果資料存儲尚未存在，則建立
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        },
    });
    // 將 Token 儲存到資料存儲
    await db.put(STORE_NAME, token, FCM_TOKEN);
};

// 從 IndexedDB 獲取已儲存的 FCM Token
export const getFcmTokenFromDB = async (): Promise<string | undefined> => {
    // 開啟資料庫
    const db = await openDB(DB_NAME, 1);
    // 獲取 Token 值，若無則返回 undefined
    return await db.get(STORE_NAME, FCM_TOKEN);
};

// 儲存 FCM Token 到 IndexedDB
export const saveServerToken = async (token: string) => {
    // 開啟或建立資料庫
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) { // 資料庫升級時執行
            // 如果資料存儲尚未存在，則建立
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        },
    });
    // 將 Token 儲存到資料存儲
    await db.put(STORE_NAME, token, SERVER_TOKEN);
};

// 從 IndexedDB 獲取已儲存的 FCM Token
export const getServerTokenFromDB = async (): Promise<string | undefined> => {
    // 開啟資料庫
    const db = await openDB(DB_NAME, 1);
    // 獲取 Token 值，若無則返回 undefined
    return await db.get(STORE_NAME, SERVER_TOKEN);
};

// 刪除 IndexedDB 中的指定資料
export const deleteSpecificData = async ( key: string | number) => {
    return new Promise<void>((resolve, reject) => {
      // 開啟資料庫
      const request = indexedDB.open(DB_NAME);
  
      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
  
        // 開啟讀寫交易
        const transaction = db.transaction(STORE_NAME, "readwrite");
        const store = transaction.objectStore(STORE_NAME);
  
        // 刪除指定 key 的資料
        const deleteRequest = store.delete(key);
  
        deleteRequest.onsuccess = () => {
          console.log(`Data with key '${key}' deleted successfully.`);
          resolve();
        };
  
        deleteRequest.onerror = (event) => {
          console.error("Error deleting data:", event);
          reject(event);
        };
  
        transaction.oncomplete = () => db.close();
      };
  
      request.onerror = (event) => {
        console.error("Error opening database:", event);
        reject(event);
      };
    });
  };