import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { Service } from "../model/application/service"
import { SERVER_TOKEN } from "../../idb"

export const ApiGetServiceByID=async(serviceId:string)=>{
    const queryStr=gql`
        query ApiGetServiceByID($serviceID:String!){
            getServiceByID(serviceID:$serviceID){
                id
                name
                appLocked
                enabled
                users{
                    id
                    name
                    description
                    emergencyNo
                }
                serviceUsers{ # old: siteUsers
                    id
                    user{
                        id
                        name
                        roleID
                        description
                        emergencyNo
                    }
                    callPriority
                    }
                    nvrs{
                        id
                        # site{
                        #   id
                        # }
                        service {
                            id
                        }
                        name
                        host
                        apiPort
                        streamPort
                        username
                        password
                    }
                    devices{
                        id
                        name
                        description
                        enabled
                        state # old: status
                        batteryLevel
                        lastReportTime
                        firmwareVersion
                    }
                    site {
                        id
                        name
                    }
                }
            }
    `
    const variables={
        serviceID:serviceId
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getServiceByID:Service}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get service");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting service");
    }

}