import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { application } from "express"
import { SERVER_TOKEN } from "../../idb"


export const ApiCheckPhoneNumber=async(phoneNumber:string)=>{
    const queryStr=gql`
        query ApiCheckPhoneNumber($num:String!){
            checkPhoneNumber(num:$num)
        }
    `
    const variables={
        num:phoneNumber
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{checkPhoneNumber:boolean}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to check phone number");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while checking phone number");
    }

}