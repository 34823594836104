import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { SERVER_TOKEN } from "../../idb"
import { Prompt } from "../model/application/prompt"



export const ApiGetSitePrompts=async(serviceId:string)=>{
    const queryStr=gql`
        query getSitePrompts($serviceID:String!){          
            getSitePrompts(serviceID:$serviceID,replyStatus:[0,1,2]){
                id
                action
                checkTime
                message
                replyStatus
            }
        }
    `
    const variables={
        serviceID:serviceId
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getSitePrompts:Prompt[]}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get events");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting events");
    }

}