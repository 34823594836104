import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { FunctionInput } from "../model/application/functionInput"
import { SERVER_TOKEN } from "../../idb"

export const ApiUpdateUserServiceFunctions=async(userId:string,serviceId:string,inputs:FunctionInput[])=>{
    const mutationStr=gql`
        mutation ApiUpdateUserServiceFunctions($userID:String!,$serviceID:String!,$inputs:[FunctionInput!]!){
            updateUserServiceFunctions(userID:$userID,serviceID:$serviceID,inputs:$inputs)
        }
    `
    const variables={
        userID:userId,
        serviceID:serviceId,
        inputs:inputs
    }
    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)

    try {
        const res = await makeGraphQLRequest<{ updateUserServiceFunctions: boolean }>(mutationStr, variables,applicationClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to update UserServiceFunctions");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while updating UserServiceFunctions");
    }
}