import { List, Image } from "antd-mobile"
import moment from "moment"

interface DeviceItemProps{
    devices:DeviceProps[]
    deviceViewType:string
}

export interface DeviceProps{
    name:string
    lastReportTime:string
    batteryLevel:number
    firmwareVersion:string
}

export const DeviceItem=(props:DeviceItemProps)=>{
    const imgUrlPrefix='../../device/image'

    return(
        <List header={<div style={{color:"black",fontSize:'10px'}}>{props.deviceViewType}</div>} style={{background:'#F0F0F0'}}>
            {props.devices.map((item:DeviceProps) => (
                <List.Item
                    key={item.name}
                    description={
                        <div style={{marginLeft:'10px',fontSize:'12px'}}>
                            {`Last Callback: ${moment(item.lastReportTime).fromNow()}`}<br/>
                            {`Version: ${item.firmwareVersion}`}
                        </div>
                    }
                    extra={<div style={{color:props.deviceViewType==="Normal"?"#75BD76":'#fa213b'}}>{props.deviceViewType}</div>}
                >
                    <div style={{display:'flex',marginLeft:'10px',fontSize:'15px'}}>
                        <span style={{marginRight:'8px'}}>{item.name}</span>
                        {
                            item.batteryLevel===100?
                            <Image src={`${imgUrlPrefix}/battery100.png`} style={{width:'20px',height:'20px'}}/>
                            :
                            item.batteryLevel>50&&item.batteryLevel<100?
                            <Image src={`${imgUrlPrefix}/battery75.png`} style={{width:'20px',height:'20px'}}/>
                            :
                            item.batteryLevel>25&&item.batteryLevel<=50?
                            <Image src={`${imgUrlPrefix}/battery50.png`} style={{width:'20px',height:'20px'}}/>
                            :
                            item.batteryLevel>15&&item.batteryLevel<=25?
                            <Image src={`${imgUrlPrefix}/battery25.png`} style={{width:'20px',height:'20px'}}/>
                            :
                            item.batteryLevel>5&&item.batteryLevel<=15?
                            <Image src={`${imgUrlPrefix}/battery15.png`} style={{width:'20px',height:'20px'}}/>
                            :
                            item.batteryLevel>0&&item.batteryLevel<=5?
                            <Image src={`${imgUrlPrefix}/battery5.png`} style={{width:'20px',height:'20px'}}/>
                            :
                            item.batteryLevel===0&&
                            <Image src={`${imgUrlPrefix}/battery0.png`} style={{width:'20px',height:'20px'}}/>
                        }
                        <span style={{marginLeft:'5px',color:'#ADADAD',fontSize:'14px'}}>{item.batteryLevel}%</span>
                    </div>
                </List.Item>
            ))}
        </List>
    )
}