import React, { useEffect } from 'react'
import { PageLayout } from '../../components/pageLayout'
import { PageNavbar } from '../../components/pageNavbar'
import { Button, Form, Input } from 'antd-mobile'
import { styled } from 'styled-components'
import { ApiChangePassword } from '../../../graphql/mutation/changePassword'
import { Modal, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'


const FormContainer=styled.div`
    padding: 0 15px;
    width: 100%;
`
interface FormInputProps{
    oldPassword:string,
    newPassword:string,
    reEnterPassword:string
}

export const EditPasswordPage = () => {
    const navigate=useNavigate()
    const [input, setInput] = React.useState<FormInputProps>({oldPassword:'',newPassword:'',reEnterPassword:''})
    const [btnDisabled,setBtnDisabled]=React.useState(true)
    const [modalVisible, setModalVisible] = React.useState<{status:boolean,open:boolean}>({status:false,open:false})// status: true=>edit failed, false=>edit success
    const { serviceId } = useParams<{ serviceId: string }>();
    
    useEffect(()=>{
        checkBtnDisabled()
    },[input])

    const handleChangePassword=()=>{
        ApiChangePassword(input.oldPassword,input.newPassword)
        .then((res)=>{
            if(res.changePassword===true){
                setModalVisible({status:false,open:true})
            }
            else{
                setModalVisible({status:true,open:true})
            }
        })
        .catch((err)=>{
            setModalVisible({status:true,open:true})
        })
    }

    const checkBtnDisabled=()=>{
        if(input.oldPassword!==""&&input.newPassword.length>5&&input.reEnterPassword.length>5&&input.newPassword===input.reEnterPassword){
            setBtnDisabled(false)
        }
        else{
            setBtnDisabled(true)
        }
    }

  return (
    <PageLayout 
        header={
            <PageNavbar title="Edit Password" backTo={`/services/${serviceId}/sys-settings`}/>
        }
        content={
            <>
                <FormContainer>
                    <Form>
                        <Form.Item label='Old Password' name='oldpassword' rules={[{ required: true,message:'Required Item'},{type:"string",min:6,message:""}]}>
                            <Input clearable type='password' value={input.oldPassword} onChange={(val:string)=>{
                                setInput({...input,oldPassword:val})
                            }}/>
                        </Form.Item>
                        <Form.Item label='New Password' name='newpassword' rules={[{ required: true,message:'Required Item'},{type:"string",min:6,message:"Length of password must be greater or equal 6 characters"}]}>
                            <Input clearable type='password' value={input.newPassword} onChange={(val:string)=>{
                                setInput({...input,newPassword:val})
                            }}/>
                        </Form.Item>
                        <Form.Item label='Re-enter New Password' name='reEnterPassword' rules={[{ required: true,message:'Passwords do not match'},{pattern:RegExp(`^${input.newPassword}$`),message:'Passwords do not match'}]}>
                            <Input clearable type='password' onChange={(val:string)=>{
                                setInput({...input,reEnterPassword:val})
                            }}/>
                        </Form.Item>
                    </Form>
                    <Button block color='primary' size='middle' className='colorPrimary' disabled={btnDisabled}
                        onClick={()=>{
                            handleChangePassword()
                        }}>
                        CONFIRM EDIT PASSWORD
                    </Button>
                </FormContainer>

                <Modal
                    title={ 
                    <>
                        {
                            modalVisible.status===true?
                            <>
                                <CloseCircleOutlined style={{color:'red',paddingRight:'10px'}}/>
                                <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                                    Edit password failed
                                </Typography.Text>
                            </>
                            :
                            <>
                                <CheckCircleOutlined style={{color:'green',paddingRight:'10px'}}/>
                                <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                                    Edit password success
                                </Typography.Text>
                            </>
                        }
                    </>
                    }
                    style={{top:"30%",height:'55px',zIndex:99}}
                    width={'80%'}
                    open={modalVisible.open}
                    onCancel={()=>{setModalVisible({...modalVisible,open:false})}}
                    maskClosable={false}
                    closable={false}
                    footer={
                        <Button color="primary" size='small' className='colorPrimary' 
                            onClick={()=>{
                                setModalVisible({...modalVisible,open:false})
                                navigate("/services/"+serviceId+'/sys-settings') 
                            }}>Confirm</Button>
                    }
                >
                </Modal>
            </>
        }
    />
  )
}
