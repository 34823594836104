import React, { useEffect } from 'react'
import { PageLayout } from '../../components/pageLayout'
import { PageNavbar } from '../../components/pageNavbar'
import { styled } from 'styled-components'
import { List } from 'antd-mobile'
import { ConfigProvider, FloatButton, notification, Spin } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PermissionsPage } from './permissions'
import { ApiGetServiceUsers } from '../../../graphql/query/getServiceUsers'


const FormContainer=styled.div`
    padding: 0 15px;
    width: 100%;
`

interface UserDataProps{
    id:string
    name:string
}

export const EditPermissionsPage = () => {
    const location=useLocation()
    const [loading, setLoading] = React.useState(false)
    const [userData,setUserData]=React.useState<UserDataProps[]>([])
    const navigate=useNavigate()
    const { serviceId } = useParams<{ serviceId: string }>();

    useEffect(()=>{
        handleGetServiceUsers()
    },[])

    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: `${description}`,
        });
    }

    const handleGetServiceUsers=()=>{
        setLoading(true)
        ApiGetServiceUsers(serviceId||'')
        .then((res:any)=>{
            if(res.getServiceUsers!==null){
                const users:UserDataProps[]=[]

                for(let i=0;i<res.getServiceUsers.length;i++){
                    users.push({id:res.getServiceUsers[i]?.user.id,name:res.getServiceUsers[i]?.user.name})
                }
                setUserData(users.sort((a,b)=>{
                     // 使用正則表達式判斷是否為英文字符
                    const isAEnglish = /^[a-zA-Z]/.test(a.name);
                    const isBEnglish = /^[a-zA-Z]/.test(b.name);

                    if (isAEnglish && !isBEnglish) {
                        // a 是英文，b 不是，a 排前面
                        return -1;
                    } else if (!isAEnglish && isBEnglish) {
                        // b 是英文，a 不是，b 排前面
                        return 1;
                    } else {
                        // 如果都為英文或都為非英文，直接用 localeCompare 比較
                        return a.name.localeCompare(b.name, 'zh-Hans');
                    }
                }))
            }
            else{
                openFailedNotification("Failed to get data")
            }
        })
        .catch((error:any)=>{
            openFailedNotification("Failed to get data")
        })
        .finally(()=>{
            setLoading(false)
        })
    }



  return (
    <>
    {
        location.pathname==="/services/"+serviceId+"/sys-settings/editPermissions"?
        <PageLayout 
            header={
                <PageNavbar title="Edit Permissions" backTo={"/services/"+serviceId+"/sys-settings"}/>
            }
            content={
                <>
                    <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
                    <FormContainer>
                        <List>
                            {
                                userData.map((item:UserDataProps)=>{
                                    return(
                                        <List.Item key={item.id} arrowIcon={false} onClick={()=>{
                                            navigate("/services/"+serviceId+`/sys-settings/editPermissions/permissions?id=${item.id}`)
                                        }}>{item.name}</List.Item>
                                    )
                                })
                            }
                        </List>
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token，影响范围大
                                    colorPrimary: '#75BD76',
                                },
                            }}
                        >
                            <FloatButton icon={<SyncOutlined />} type='primary' style={{ insetInlineEnd: 24 }} onClick={()=>{handleGetServiceUsers()}}/>
                        </ConfigProvider>
                    </FormContainer>
                </>
            }
        />
        :
        <PermissionsPage/>
    }
    
    </>
    
  )
}
