import React, { useEffect } from 'react'
import { PageLayout } from '../../components/pageLayout'
import { PageNavbar } from '../../components/pageNavbar'
import { styled } from 'styled-components'
import { Button, Form, Input } from 'antd-mobile'
import { ApiGetCurrentUserData } from '../../../graphql/query/getCurrentUserData'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Modal, notification, Spin, Typography } from 'antd'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { ApiUpdateUserInfo } from '../../../graphql/mutation/updateUserInfo'
import { UserInfoInput } from '../../../graphql/model/application/userInfoInput'
import { ApiCheckPhoneNumber } from '../../../graphql/query/checkPhoneNumber'

const FormContainer=styled.div`
    padding: 0 15px;
    width: 100%;
`

export const EditBasicInfoPage = () => {
    const location=useLocation()
    const navigate=useNavigate()
    const [input,setInput]=React.useState<UserInfoInput>({name:'',description:'',emergencyNo:''})
    const [loading, setLoading] = React.useState(false)
    const [modalVisible, setModalVisible] = React.useState<{status:boolean,open:boolean}>({status:false,open:false})// status: true=>edit failed, false=>edit success
    const [form]=Form.useForm()
    const [btnDisabled,setBtnDisabled]=React.useState(false)
    const { serviceId } = useParams<{ serviceId: string }>();

    useEffect(()=>{
        if(location.pathname==='/sys-settings/editBasicInfo'){
            form.resetFields()
            handleGetUserInfo()
        }
    },[])

    const openFailedNotification = () => {
        notification.error({
            message: 'Error',
            description: "Failed to get user info",
        });
    }

    const handleGetUserInfo=async()=>{
        setLoading(true)
        ApiGetCurrentUserData()
        .then((res:any)=>{
            if(res.getCurrentUserData!==null){
                const initValue={
                    fullName:res.getCurrentUserData.name,
                    comments:res.getCurrentUserData.description,
                    emergencyNumber:res.getCurrentUserData.emergencyNo
                }
                form.setFieldsValue(initValue)
            }
            else{
                openFailedNotification()
            }
        })
        .catch((err:any)=>{
            openFailedNotification()
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    const handleCheckPhoneNumber=(val:string)=>{
        ApiCheckPhoneNumber(val)
        .then((res)=>{
            if(res.checkPhoneNumber===false){
                setBtnDisabled(true)
            }
            else{
                setBtnDisabled(false)
            }
        })
        .catch((err)=>{
            setBtnDisabled(true)
        })
    }

    const handleEditBasicInfo=()=>{
        const newInput={
            name:form.getFieldValue('fullName'),
            description:form.getFieldValue('comments'),
            emergencyNo:form.getFieldValue('emergencyNumber')
        }
        ApiUpdateUserInfo(newInput)
        .then((res)=>{
            if(res.updateUserInfo===true){
                setModalVisible({status:false,open:true})
            }
            else{
                setModalVisible({status:true,open:true})
            }
        })
        .catch((err)=>{
            setModalVisible({status:true,open:true})
        })
    }

  return (
    <>
        <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
        <PageLayout 
            header={
                <PageNavbar title="Edit Basic Info" backTo={`/services/${serviceId}/sys-settings`}/>
            }
            content={
                <>
                    <FormContainer>
                        <Form
                            form={form}
                            initialValues={{fullName:'',comments:'',emergencyNumber:''}}
                        >
                            <Form.Item label='Full Name' name='fullName' rules={[{ required: true,message:'Name cannot be blank'}]}>
                                <Input clearable value={input.name} onChange={(val:string)=>{setInput({...input,name:val})}}/>
                            </Form.Item>
                            <Form.Item label='Comments' name='comments' rules={[{ required: true}]}>
                                <Input clearable disabled value={input.description} />
                            </Form.Item>
                            <Form.Item label='Emergency Phone Number' name='emergencyNumber' rules={[{ required: true,message:'Wrong format for telephone number!'},{pattern:RegExp("^09\\d{8}$"),message:'Wrong format for telephone number!'}]}>
                                <Input clearable value={input.emergencyNo} onChange={(val:string)=>{
                                    setInput({...input,emergencyNo:val})
                                    handleCheckPhoneNumber(val)
                                }}/>
                            </Form.Item>
                        </Form>
                        <Button block color='primary' size='middle' disabled={btnDisabled} className='colorPrimary' style={{marginTop:'20px'}}
                            onClick={()=>{
                                handleEditBasicInfo()
                            }}>
                            Edit
                        </Button>
                    </FormContainer>
                    <Modal
                        title={ 
                        <>
                            {
                                modalVisible.status===true?
                                <>
                                    <CloseCircleOutlined style={{color:'red',paddingRight:'10px'}}/>
                                    <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                                        Update failed
                                    </Typography.Text>
                                </>
                                :
                                <>
                                    <CheckCircleOutlined style={{color:'green',paddingRight:'10px'}}/>
                                    <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                                        Update success
                                    </Typography.Text>
                                </>
                            }
                        </>
                        }
                        style={{top:"30%",height:'55px',zIndex:99}}
                        width={'80%'}
                        visible={modalVisible.open}
                        onCancel={()=>{setModalVisible({...modalVisible,open:false})}}
                        maskClosable={false}
                        closable={false}
                        footer={
                            <Button color="primary" size='small' className='colorPrimary' 
                                onClick={()=>{
                                    setModalVisible({...modalVisible,open:false})
                                    if(modalVisible.status===false){
                                        navigate("/services/"+serviceId+'/sys-settings')
                                    }
                                }}>Confirm</Button>
                        }
                    >
                    </Modal>
                </>
            }
        />
    </>
  )
}
