import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { SERVER_TOKEN } from "../../idb"


export const ApiResetSecureAlarmRules=async(serviceId:string)=>{
    const mutationStr=gql`
        mutation resetSecureAlarmRules($serviceID:ID!){
            resetSecureAlarmRules(serviceID:$serviceID)
        }

    `
    const token=localStorage.getItem(SERVER_TOKEN)
    const variables={
        serviceID: serviceId,
    }
    applicationClient.setHeader("authorization",`Bearer ${token}`)

    try {
        const res = await makeGraphQLRequest<{ resetSecureAlarmRules: boolean }>(mutationStr, variables,applicationClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to connect to reset SecureAlarmRules");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while resetting SecureAlarmRules");
    }

}