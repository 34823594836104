import { gql } from "graphql-request"
import { DeviceView } from "../model/application/deviceView"
import { SERVER_TOKEN } from "../../idb"
import { applicationClient, makeGraphQLRequest } from "../url"
import { Device } from "../model/application/device"

interface DeviceResult{
    alarm:Device[]
    lowBattery:Device[]
    offline:Device[]
}

export const ApiGetDevicesByViewForAlarmCount=async(serviceId:string)=>{
    const queryStr=gql`
        query ApiGetDevicesByViewForAlarmCount($serviceId:String!){
            alarm:getDevicesByView(serviceID:$serviceId, view:ALARM){
                id
                name
                lastReportTime
                state # status
                batteryLevel
                firmwareVersion
            },
            lowBattery:getDevicesByView(serviceID:$serviceId, view:LOW_BATTERY){
                id
                name
                lastReportTime
                state # status
                batteryLevel
                firmwareVersion
            },
            offline:getDevicesByView(serviceID:$serviceId, view:OFFLINE){
                id
                name
                lastReportTime
                state # status
                batteryLevel
                firmwareVersion
            }
        }
    `
    const variables={
        serviceId: serviceId,
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<DeviceResult>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get events");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting events");
    }

}