import React from 'react'
import { styled } from 'styled-components';

interface PageLayoutProps{
    header: JSX.Element;
    content: JSX.Element;
}

const Header=styled.header`
    position:fixed;
    z-index:100;
    display:flex;
    background:#75BD76;
    height:50px;
    width:100%;
`
const Main=styled.main`
    padding-top:50px;
`

export const PageLayout = (props:PageLayoutProps) => {

  return (
    <>
        <section>
            <Header>
                {props.header}
            </Header>
            <Main>
                {props.content}
            </Main>
        </section>
    </>
  )
}
