import { gql } from "graphql-request"
import { authClient, makeGraphQLRequest } from "../url"


export const ApiCheckPasswordResetCode=async(email:string,code:string)=>{
    const queryStr=gql`
        query ApiCheckPasswordResetCode($email:String!,$code:String!){
            checkPasswordResetCode(email:$email,code:$code)
        }
    `
    const variables={
        email:email,
        code:code,
    }
    
    try {
        const res=makeGraphQLRequest<{checkPasswordResetCode:boolean}>(queryStr,variables,authClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to check password reset code");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while checking password reset code");
    }

}