import { Button, Divider,Form,Grid, Input } from 'antd-mobile'
import FunctionButton from '../../components/functionButton'
import styled from 'styled-components'
import { Badge, Modal, notification, Space, Spin, Typography } from 'antd'
import { ApiGetUserServiceFunctions } from '../../../graphql/query/getUserServiceFunction'
import { defaultPermissionsItem, PermissionsItemProps } from '../../settings/editPermissions/permissions'
import { ApiGetPlatFormUserByEmail } from '../../../graphql/query/getPlatformUserByEmail'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiLogin } from '../../../graphql/query/login'
import { ACCOUNT_EMAIL } from '../../../idb'
import { ApiSetSecureOnOff } from '../../../graphql/mutation/setSecureOnOff'
import { ApiGetServiceByID } from '../../../graphql/query/getServiceById'
import { ApiGetDevicesByViewForAlarmCount } from '../../../graphql/query/getDevicesByViewForAlarmCount'
import { ApiHasSecureAlarmEvents } from '../../../graphql/query/hasSecureAlarmEvents'
import { ApiResetSecureAlarmRules } from '../../../graphql/mutation/resetSecureAlarmRules'
import { UnlockAlarmModal } from './unlockAlarmModal'
import { ControlSecureSystemModal } from './controlSecureSystemModal'
import { ForceEnableSecureSystemModal } from './forceEnableSecureSystemModal'

interface ContentProps{
    pageForceUpdate:boolean
}

const GridContainer=styled(Grid)`
    padding:10px 10px;
`
const GridItem=styled(Grid.Item)`
    margin:0 10px;
`

const DivideLine=styled(Divider)`
    margin:10px 10px
`

export const Content = (props:ContentProps) => {
    const imgUrlPrefix='../home/image'
    const navigate=useNavigate()
    const [btnVisible,setBtnVisible]=React.useState<PermissionsItemProps>(defaultPermissionsItem)
    const [passwordInputModalVisible, setPasswordInputModalVisible] = React.useState<boolean>(false)
    const [openSecuritymodalVisible, setOpenSecurityModalVisible] = React.useState<boolean>(false)
    const [reconfirmModalVisible, setReconfirmModalVisible] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState(false)
    const [modalLoading, setModalLoading] = React.useState(false)
    const [passwordInput, setPasswordInput] = React.useState("")
    const [forceUpdate, setForceUpdate] = React.useState(false)
    const { serviceId } = useParams<{ serviceId: string }>();

    React.useEffect(()=>{
        handleGetUserServiceFunctions()
    },[])

    React.useEffect(()=>{
        handleGetUserServiceFunctions()
    },[serviceId,forceUpdate,props.pageForceUpdate])

    React.useEffect(()=>{
        setPasswordInput("")
    },[passwordInputModalVisible])

    const openNotification = (description:string) => {
        notification.success({
            message: 'Success',
            description: `${description}`,
        });
    }
    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: description,
        });
    }

    const handleGetUserServiceFunctions=()=>{
        setLoading(true)
        ApiGetPlatFormUserByEmail(localStorage.getItem(ACCOUNT_EMAIL)||'')
        .then((result:any)=>{
            let initBtnVisible:PermissionsItemProps=defaultPermissionsItem
            ApiGetDevicesByViewForAlarmCount(serviceId||"")
            .then((res:any)=>{
                if(res!==null){
                    initBtnVisible.deviceAlarm=res.alarm.length+res.lowBattery.length+res.offline.length
                }
                else{
                    initBtnVisible.deviceAlarm=0
                }
                return initBtnVisible
            })
            .then((preBtnVisible)=>{
                ApiGetServiceByID(serviceId||'')
                .then((res:any)=>{
                    if(res.getServiceByID!==null){
                        const service=res.getServiceByID
                        preBtnVisible.secureOnOff=service?.enabled
                    }
                    else{
                        openFailedNotification("ApiGetServiceByID failed")
                    }
                    ApiHasSecureAlarmEvents(serviceId||'')
                    .then((res)=>{
                        if(res.hasSecureAlarmEvents===true){
                            preBtnVisible.unlockAlarmStatus=true
                        }
                        else if(res.hasSecureAlarmEvents===false){
                            preBtnVisible.unlockAlarmStatus=false
                        }
                        else{
                            openFailedNotification("ApiHasSecureAlarmEvents failed")
                        }
                    })
                    return preBtnVisible
                })
                .then((secndBtnVisible)=>{
                    ApiGetUserServiceFunctions(result.getPlatformUserByEmail.id||"",serviceId||'')
                    .then((res:any)=>{
                        if(res.getUserServiceFunctions!==null){
                            const permissions=res.getUserServiceFunctions
                            secndBtnVisible={
                                ...secndBtnVisible,
                                secure:permissions.filter((item:any)=>item.baseFunctionID==="secure.on_off")[0]?.enabled,
                                camera:permissions.filter((item:any)=>item.baseFunctionID==="nvr.view")[0]?.enabled,
                                reminder:permissions.filter((item:any)=>item.baseFunctionID==="secure.reply.view")[0]?.enabled,
                                events:permissions.filter((item:any)=>item.baseFunctionID==="device.event.view")[0]?.enabled,
                                device:permissions.filter((item:any)=>item.baseFunctionID==="device.view")[0]?.enabled,
                                member:permissions.filter((item:any)=>item.baseFunctionID==="user.view")[0]?.enabled,
                                deviceGroup:permissions.filter((item:any)=>item.baseFunctionID==="device.group.edit")[0]?.enabled,
                                ruleEngine:permissions.filter((item:any)=>item.baseFunctionID==="rule.device2device.edit")[0]?.enabled,
                                smartConfig:permissions.filter((item:any)=>item.baseFunctionID==="device.config")[0]?.enabled,
                                cameraConfig:permissions.filter((item:any)=>item.baseFunctionID==="nvr.edit")[0]?.enabled,
                                deviceToNVR:permissions.filter((item:any)=>item.baseFunctionID==="device.map.camera.edit")[0]?.enabled,
                                unlockAlarm:permissions.filter((item:any)=>item.baseFunctionID==="rule.secure.alarm.dismiss")[0]?.enabled,
                            }
                            setBtnVisible(secndBtnVisible)
                        }
                        else{
                            openFailedNotification("ApiGetUserServiceFunctions failed")
                        }
                    })
                })
                .catch((error:any)=>{
                    openFailedNotification("Failed to get data : "+error)
                })
            })
        })
        .catch((error)=>{
            openFailedNotification("Failed to get data : "+error)
        })
        .finally(()=>{
            setTimeout(() =>setLoading(false), 300);
        })
    }

    const handleLogin=()=>{
        setModalLoading(true)
        ApiLogin(localStorage.getItem(ACCOUNT_EMAIL)||"",passwordInput)
        .then((res)=>{
            if(res.token.login.token!==null||res.token.login.token!==undefined || res.token.login.token!==''){// 登入成功
                if(btnVisible.secureOnOff===true){// 警網狀態是 On
                    handleSetSecureOnOff(false)// 直接關閉警網
                }
                else{// 警網狀態是 Off
                    ApiGetDevicesByViewForAlarmCount(serviceId||"")//檢查是否有裝置異常警報
                    .then((res:any)=>{
                        if(res!==null){
                            if(res.alarm.length+res.lowBattery.length+res.offline.length>0){//有裝置異常警報
                                setOpenSecurityModalVisible(true)// 顯示強制開啟保全modal
                                setPasswordInputModalVisible(false)
                                setModalLoading(false)
                            }
                            else if(res.alarm.length+res.lowBattery.length+res.offline.length===0){//沒有裝置異常警報
                                handleSetSecureOnOff(true)// 直接開啟警網
                            }
                        }
                        else{
                            openFailedNotification("Failed to get devices alarm count")
                            setModalLoading(false)
                        }
                    })
                }
            }
            else{
                openFailedNotification("Incorrect Password")
                setModalLoading(false)
            }
        })
        .catch((err)=>{
            openFailedNotification("Incorrect Password")
            setModalLoading(false)
        })
    }
    
    const turnAllSecureModalOff=()=>{
        setPasswordInputModalVisible(false)
        setOpenSecurityModalVisible(false)
    }

    const handleSetSecureOnOff=(on:boolean,force?:boolean)=>{
        setModalLoading(true)
        ApiSetSecureOnOff(serviceId||"",on,force)
        .then((res)=>{
            if(res.setSecureOnOff===true||res.setSecureOnOff===false){// 警網狀態改變成功
                turnAllSecureModalOff()
                setForceUpdate(!forceUpdate)
            }
            else{
                turnAllSecureModalOff()
                openFailedNotification("Failed to set secure on/off")
            }
        })
        .catch((err)=>{
            turnAllSecureModalOff()
            openFailedNotification("Failed set secure on/off")
        })
        .finally(()=>{
            setModalLoading(false)
        })
    }

    const handleUnlockAlarm=()=>{
        // setLoading(true)
        ApiHasSecureAlarmEvents(serviceId||'')
        .then((res)=>{
            if(res.hasSecureAlarmEvents===true){//有警報
                if(btnVisible.unlockAlarmStatus===true){//true=>false 想要關閉警報
                    setLoading(true)
                    ApiResetSecureAlarmRules(serviceId||'').then((res:{resetSecureAlarmRules: boolean;})=>{
                        if(res.resetSecureAlarmRules){
                            setBtnVisible({...btnVisible,unlockAlarmStatus:false})//關閉警報
                            openNotification("Unlock Alarm successfully")
                        }
                    })
                    .catch((err)=>{
                        openFailedNotification("ApiResetSecureAlarmRules Failed")
                    })
                    .finally(()=>{
                        setLoading(false)
                    })
                }    
            }else if(res.hasSecureAlarmEvents===false){//沒有警報
                openFailedNotification("No Alarm Events")
            }
            else{
                openFailedNotification("ApiHasSecureAlarmEvents Failed")
            }
        })
        .catch((err)=>{
            openFailedNotification("ApiHasSecureAlarmEvents Failed"+err)
        })
        // .finally(()=>{
        //     setLoading(false)
        //     // setTimeout(() =>setLoading(false), 1000);
        // })
    }


  return (
    <>
        {
            <>
            <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
            {
                btnVisible.secure===true&&
                <>
                    <GridContainer columns={2} gap={20}>
                        {
                            btnVisible.secureOnOff===true?//enabled==true
                            <GridItem>
                                <FunctionButton image={{src:`${imgUrlPrefix}/site-on.png`}} title='SECURE' clickEvent={()=>{setPasswordInputModalVisible(true)}}></FunctionButton>
                            </GridItem>
                            :
                            <GridItem>
                                <FunctionButton image={{src:`${imgUrlPrefix}/site-off.png`}} title='SECURE' clickEvent={()=>{setPasswordInputModalVisible(true)}}></FunctionButton>
                            </GridItem>
                        }
                        <GridItem>
                            <FunctionButton image={{src:`${imgUrlPrefix}/muti-sites-off-none.png`}} title='Multi SECURE' clickEvent={()=>{}}></FunctionButton>
                        </GridItem>
                    </GridContainer>

                    <DivideLine />
                </>
            }
            <GridContainer columns={3} gap={20}>
                {
                    btnVisible.camera===true&&
                    <Grid.Item>
                        <FunctionButton image={{src:`${imgUrlPrefix}/new-camera-none.png`}} title='Camera' clickEvent={()=>{}}/>
                    </Grid.Item>
                }
                {
                    btnVisible.reminder===true&&
                    <Grid.Item>
                        <FunctionButton image={{src:`${imgUrlPrefix}/notice-reply.png`}} title='Reminder' clickEvent={()=>{navigate("/services/"+serviceId+"/reminder")}}/>
                    </Grid.Item>
                }
                {
                    btnVisible.events===true&&
                    <Grid.Item>
                        <FunctionButton image={{src:`${imgUrlPrefix}/new-events.png`}} title='Events' clickEvent={()=>{navigate("/services/"+serviceId+"/sys-events")}}/>
                    </Grid.Item>
                }
                {
                    btnVisible.device===true&&
                    <Grid.Item>
                        <Badge count={btnVisible.deviceAlarm}>
                            <FunctionButton image={{src:`${imgUrlPrefix}/new-devices.png`}} title='Device' clickEvent={()=>{navigate("/services/"+serviceId+"/device")}}/>
                        </Badge>
                    </Grid.Item>
                }
                {
                    btnVisible.member===true&&
                    <Grid.Item>
                        <FunctionButton image={{src:`${imgUrlPrefix}/new-members.png`}} title='Member' clickEvent={()=>{navigate("/services/"+serviceId+'/member')}}/>
                    </Grid.Item>
                }
                {
                    btnVisible.unlockAlarm===true&&
                    (
                        btnVisible.unlockAlarmStatus===true?
                        <Grid.Item>
                            <FunctionButton image={{src:`${imgUrlPrefix}/alarm-reset.png`}} title='Unlock Alarm' clickEvent={()=>{setReconfirmModalVisible(true)}}/>
                        </Grid.Item>
                        :
                        <Grid.Item>
                            <FunctionButton image={{src:`${imgUrlPrefix}/alarm-reset-none-red.png`}} title='Unlock Alarm' clickEvent={()=>{}}/>
                        </Grid.Item>
                    )
                }
                <UnlockAlarmModal visible={reconfirmModalVisible} setVisible={setReconfirmModalVisible} unlockFunction={handleUnlockAlarm}/>
            </GridContainer>

            <ControlSecureSystemModal 
                pageBtnVisible={btnVisible} 
                visible={passwordInputModalVisible} 
                setVisible={setPasswordInputModalVisible}
                loading={modalLoading}
                input={passwordInput}
                setInput={setPasswordInput}
                loginFunction={handleLogin}
            />

            <ForceEnableSecureSystemModal
                visible={openSecuritymodalVisible}
                setVisible={setOpenSecurityModalVisible}
                loading={modalLoading}
                handleSetSecureOnOffFunction={handleSetSecureOnOff}
            />
            </>
        }
    </>
  )
}
