import { ConfigProvider, FloatButton, notification, Spin } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import React from 'react'
import { PageLayout } from '../../components/pageLayout'
import { PageNavbar } from '../../components/pageNavbar'
import { DeviceView } from '../../../graphql/model/application/deviceView'
import { ApiGetDevicesByView } from '../../../graphql/query/getDevicesByView'
import { DeviceItem, DeviceProps } from '../../components/deviceItem'
import { DisconnectedDeviceItem } from '../../components/disconnectedDeviceItem'
import { useParams } from 'react-router-dom'


export const DevicePage = () => {
    const [loading, setLoading] = React.useState(false)
    const [alarmDevices, setAlarmDevices] = React.useState<DeviceProps[]>([])
    const [normalDevices, setNormalDevices] = React.useState<DeviceProps[]>([])
    const [lowBatteryDevices, setLowBatteryDevices] = React.useState<DeviceProps[]>([])
    const [offlineDevices, setOfflineDevices] = React.useState<DeviceProps[]>([])
    const {serviceId} = useParams<{ serviceId: string }>()

    React.useEffect(() => {
        initGetDevicesByView()
    }, [])

    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: `${description}`,
        });
    }

    const initGetDevicesByView = () => {
        handleGetDevicesByView(DeviceView.ALARM)
        handleGetDevicesByView(DeviceView.HEALTHY)
        handleGetDevicesByView(DeviceView.OFFLINE)
        handleGetDevicesByView(DeviceView.LOW_BATTERY)
    }

    const handleGetDevicesByView = (deviceViewType:DeviceView) => {
        setLoading(true)
        ApiGetDevicesByView(serviceId||"",deviceViewType)
        .then((res)=>{
            if(res.getDevicesByView!==null){
                const deviceItem:DeviceProps[]=[]
                res.getDevicesByView.map((item:any)=>{
                    deviceItem.push({
                        name:item.name,
                        lastReportTime:item.lastReportTime,
                        batteryLevel:item.batteryLevel,
                        firmwareVersion:item.firmwareVersion
                    })
                })
                const tempSortedDeviceItem=deviceItem.sort((a:any,b:any)=>{
                    const nameA=parseInt(a.name.substring(0,2),10)
                    const nameB=parseInt(b.name.substring(0,2),10)

                    return nameA-nameB
                })
                const sortedDeviceItem=tempSortedDeviceItem.sort((a:any,b:any)=>{
                    if(a.name.substring(0,2)===b.name.substring(0,2)){
                        if(a.name.substring(a.name.length-2,a.name.length-1)==="-"&&b.name.substring(b.name.length-2,b.name.length-1)==="-"){
                            const nameA=parseInt(a.name.substring(a.name.length-1))
                            const nameB=parseInt(b.name.substring(b.name.length-1))
                            return nameA-nameB
                        }
                        else{
                            return -1
                        }
                    }
                    return 1
                })
                if(deviceViewType===DeviceView.ALARM){
                    setAlarmDevices(sortedDeviceItem)
                }
                else if(deviceViewType===DeviceView.HEALTHY){
                    setNormalDevices(sortedDeviceItem)
                }
                else if(deviceViewType===DeviceView.OFFLINE){
                    setOfflineDevices(sortedDeviceItem)
                }
                else if(deviceViewType===DeviceView.LOW_BATTERY){
                    setLowBatteryDevices(sortedDeviceItem)
                }
            }
            else{
                openFailedNotification("ApiGetDevicesByView failed")
            }
        })
        .catch((error)=>{   
            openFailedNotification("ApiGetDevicesByView failed")
        })
        .finally(()=>{
            setLoading(false)
        })
    }


  return (
    <>
        <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
          <PageLayout 
            header={
              <PageNavbar title="Reminder" backTo={"/services/"+serviceId}/>
            }
            content={
              <>
              {
                alarmDevices.length!==0&&
                <DeviceItem devices={alarmDevices} deviceViewType={'Alarm'}/>
              }
              {
                normalDevices.length!==0&&
                <DeviceItem devices={normalDevices} deviceViewType={'Normal'}/>
              }
              {
                lowBatteryDevices.length!==0&&
                <DeviceItem devices={lowBatteryDevices} deviceViewType={'Low Battery'}/>
              }
              {
                offlineDevices.length!==0&&
                <DisconnectedDeviceItem devices={offlineDevices}/>
              }

                <ConfigProvider
                        theme={{
                            token: {
                                // Seed Token，影响范围大
                                colorPrimary: '#75BD76',
                            },
                        }}
                    >
                        <FloatButton icon={<SyncOutlined />} type='primary' style={{ insetInlineEnd: 24 }} 
                        onClick={()=>{
                            initGetDevicesByView()
                        }}/>
                    </ConfigProvider>
                </>
              
            }
          />
    </>
  )
}
