import { Button, Form, Input } from 'antd-mobile'
import { CloseCircleOutlined, BellFilled } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { ApiLogin } from '../../../graphql/query/login'
import { Typography,Modal, notification, Spin, Flex, Tooltip } from 'antd'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ACCOUNT_EMAIL, FCM_TOKEN, SERVER_TOKEN } from '../../../idb'
import { ApiGetCurrentUserData } from '../../../graphql/query/getCurrentUserData'
import { onMessageListener, requestFcmToken, VAPID_KEY } from '../../../fcm'
import { ApiConnectToFcm } from '../../../graphql/mutation/connectToFcm'

// 請求通知權限並處理邏輯
export const requestPermission = async () => {
  try {

    // 獲取 FCM Token，vapidKey 是從 Firebase Console 獲得的公鑰
    const fcmToken = await requestFcmToken(VAPID_KEY)

    if (fcmToken) { // 如果成功獲取 Token
        //await saveFcmToken(token); // 儲存 Token 到 IndexedDB
        localStorage.setItem(FCM_TOKEN,fcmToken)
    }
    onMessageListener().then((payload:any) => {
        notification.success({
          message: 'Success',
          description: payload.notification.title +" by "+payload.notification.body,
        });
        console.log("App Message received..", payload); // 記錄前台接收到的訊息
    });
      
  } catch (error) { // 錯誤處理
      console.error("Permission denied or error occurred", error);
  }
};

const FormContainer=styled.div`
    margin-top: 10px;
    padding: 0 10px;
    width: 100%;
`

const ForgotPasswordText=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-right:-10px;
`

const RegisterText=styled.div`
    display:flex;
    justify-content:center;
    margin-top:15px;
`

export interface ServiceProps{
    id:string
    name:string
}

export const LoginFormPage = () => {
    const location = useLocation()
    const navigate=useNavigate()
    const [input, setInput] = React.useState({
        email: '',
        password: '',
    })
    const [modalVisible, setModalVisible] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [tooltipOpen, setTooltipOpen] = React.useState(false)// true: 未開啟通知權限 false: 已開啟通知權限
    const [fcmLoading, setFcmLoading] = React.useState(false)
    const fcmToken = localStorage.getItem(FCM_TOKEN);

    useEffect(()=>{
        getNotificationPermission().then(()=>{
            const serverToken = localStorage.getItem(SERVER_TOKEN);
            const fcmToken = localStorage.getItem(FCM_TOKEN);
        
            if (serverToken&&fcmToken) {
                ApiGetCurrentUserData()
                .then((res)=>{
                    if(res.getCurrentUserData!==null&&res.getCurrentUserData.services.length>0){
                        navigate('/services/'+res.getCurrentUserData.services[0]?.id)
                    }
                    else{
                        openFailedNotification("no service found")
                    }
                })
                .catch((error)=>{
                    openFailedNotification("fail to get current user data : "+error)
                })
            }
        })
    },[])

    const getNotificationPermission=()=>{
        return navigator.permissions.query({name:'notifications'})
        .then((permission)=>{
            if(permission.state==='granted'){// 已開啟通知權限
                if(fcmToken===null||fcmToken===undefined||fcmToken===''){// 沒有fcm token
                    setTooltipOpen(true)
                }
                else{
                    setTooltipOpen(false)
                }
            }
            else{// 未開啟通知權限
                setTooltipOpen(true)
            }
        })
    }

    const setNotificationPermission=async()=>{
        setFcmLoading(true)
        requestPermission() // 請求通知權限 & 獲取 FCM Token
        .then(()=>{
            if(fcmToken!==null||fcmToken!==undefined||fcmToken!==''){
                Notification.requestPermission(function(permission) {
                    if (permission === 'granted') {
                        setTooltipOpen(false)
                    }
                    else{
                        setTooltipOpen(true)
                    }
                })
            }
            else{
                setTooltipOpen(true)
                setNotificationPermission()
            }
        })
        .catch ((error) => {
            console.error("Permission denied or error occurred", error);
        })
        .finally (() => {
            setFcmLoading(false)
        })
    }

    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: description,
        });
    }

    const initialLocalStorage=(token:string)=>{
        localStorage.setItem(SERVER_TOKEN,token);
        localStorage.setItem(ACCOUNT_EMAIL,input.email)
    }

    // 登入前需要判別是否有fcm token以及開啟通知權限
    
    const handleLogInProcess = async(token:string) => {
        initialLocalStorage(token)

        // // 登入成功做裝置綁定
        // await requestPermission(); // 請求通知權限 & 獲取 FCM Token

        const fcmToken = localStorage.getItem(FCM_TOKEN); // 呼叫 IndexedDB 函式取得 Token

        if (fcmToken) {    
            // 裝置與帳號做綁定
            ApiConnectToFcm(fcmToken, token).then((res)=>{
                 ApiGetCurrentUserData()
                    .then((res)=>{
                        if(res.getCurrentUserData!==null&&res.getCurrentUserData.services.length>0){
                            navigate('/services/'+res.getCurrentUserData.services[0]?.id)
                        }
                        else{
                            openFailedNotification("no service found")
                        }
                    })
                    .catch((error)=>{
                        openFailedNotification("fail to get current user data : "+error)
                    })
            }).catch((err)=>{
                openFailedNotification("fail to connect to fcm : "+err)
            })
        } else {
            console.log("No Device Token found in db.");
        }

    }

    const handleLogin=()=>{
        setLoading(true)
        ApiLogin(input.email,input.password)
        .then((res)=>{
            if(res.token.login.token!==null||res.token.login.token!==undefined || res.token.login.token!==''){
                handleLogInProcess(res.token.login.token)
            }
        })
        .catch((err)=>{
            setModalVisible(true)
        })
    }

  return (
    <>
        <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
        <FormContainer>
            <Form layout='horizontal' >
                <Form.Item label='' name='username'>
                    <Input placeholder='Account (Email)' clearable value={input.email} onChange={(val:string)=>{setInput({...input,email:val})}}/>
                </Form.Item>
                <Form.Item label='' name='password' hasFeedback={true} rules={[{type:"string",min:6,message:"Length of password must be greater or equal 6 characters"}]}>
                    <Input 
                        placeholder='Password' 
                        clearable 
                        type='password' 
                        value={input.password} 
                        onChange={(val:string)=>{
                            setInput({...input,password:val})
                        }}
                    />
                </Form.Item>
            </Form>
            <ForgotPasswordText>
                <a href="/login/forgotPassword" style={{fontSize:'16px',margin:'15px 20px',border:'none',textDecoration:'none',color:'black'}}>Forgot Password?</a>
            </ForgotPasswordText>
            <Flex style={{width:'100%'}}>
                <Button disabled={tooltipOpen} loading={fcmLoading} block color='primary' size='middle' className='colorPrimary'
                    onClick={()=>{
                        handleLogin()
                    }}>
                    LOGIN
                </Button>
                <Tooltip title={"You haven't enabled notification permissions or suscribed fcm yet. Please click this button."} placement='bottomRight' open={tooltipOpen}>
                    <Spin spinning={fcmLoading} size="small">
                        <BellFilled style={{display:'flex',padding:'6px 10px 0 10px',fontSize:'24px',cursor:tooltipOpen?'pointer':'none',color:tooltipOpen?'#dc3545':'#62B689'}} onClick={()=>{setNotificationPermission()}}/>
                    </Spin>
                </Tooltip>
            </Flex>
            <RegisterText>
                <span style={{fontSize:'16px'}}>New User?<a href="/login/register" style={{fontSize:'16px',margin:'15px 20px',border:'none',textDecoration:'none',color:'#75BD76'}}>Register</a></span>
            </RegisterText>
        </FormContainer>
        <Modal
            title={ 
            <>
                <CloseCircleOutlined style={{color:'red',paddingRight:'10px'}}/>
                Login Failed
            </>
            }
            style={{top:"30%",height:'55px',zIndex:99}}
            width={'80%'}
            open={modalVisible}
            onCancel={()=>{setModalVisible(false)}}
            maskClosable={false}
            closable={false}
            footer={
                <Button color="primary" size='small' className='colorPrimary' 
                onClick={()=>{
                    setModalVisible(false)
                    setLoading(false)
                }}
                >Confirm</Button>
            }
        >
            {
                input.email===''||input.password===''?
                <Typography.Text strong style={{fontWeight:'600'}}>
                    Please enter account and password
                </Typography.Text>
                :
                <Typography.Text strong style={{fontWeight:'600'}}>
                    Invalid account or password
                </Typography.Text>
            }
        </Modal>
    </>
  )
}
