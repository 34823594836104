import './App.css';
import { LoginPage } from './pages/login';
import { HomePage } from './pages/home';
import { RegisterPage } from './pages/login/register';
import { ForgotPasswordPage } from './pages/login/forgotPassword';
import { SettingsPage } from './pages/settings';
import { EditPasswordPage } from './pages/settings/editPassword';
import { EditBasicInfoPage } from './pages/settings/editBasicInfo';
import { EditPermissionsPage } from './pages/settings/editPermissions';
import { PermissionsPage } from './pages/settings/editPermissions/permissions';
import { ReactNode, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { FCM_TOKEN, SERVER_TOKEN,} from './idb';
import { SetRequestPage } from './pages/login/forgotPassword/setRequest';
import { EnterPasswordPage } from './pages/login/forgotPassword/enterPassword';
import { EventsPage } from './pages/home/events';
import { ReminderPage } from './pages/home/reminder';
import { MemberPage } from './pages/home/member';
import { NewMemberPage } from './pages/home/member/newMember';
import { EditMemberPage } from './pages/home/member/editMember';
import { DevicePage } from './pages/home/device';
import { notification } from 'antd';
import { onMessageListener } from './fcm';


interface RequireAuthProps {
  children: ReactNode;
}

const RequireAuth = (props:RequireAuthProps) => {
  const serverToken = localStorage.getItem(SERVER_TOKEN);

  if (!serverToken) {
    return <Navigate to="/login" replace />;
  }

  return <>{props.children}</>; // 使用 Fragment 包裹 children
};



const App=()=>{

  onMessageListener().then((payload:any) => {
    notification.success({
      message: 'Success',
      description: payload.notification.title +" by "+payload.notification.body,
    });
    console.log("App Message received.", payload); // 記錄前台接收到的訊息
  });

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route element={<LoginPage/>} path='/login'>
            <Route element={<RegisterPage/>} path='/login/register' />
            <Route element={<ForgotPasswordPage/>} path='/login/forgotPassword'>
              <Route element={<SetRequestPage/>} path='/login/forgotPassword/setRequest' />
              <Route element={<EnterPasswordPage/>} path='/login/forgotPassword/enterPassword' />
            </Route>
          </Route>
          {/* 非 Login 相關路由加上 {serviceid} */}
          <Route path="/services/:serviceId" element={<RequireAuth><HomePage /></RequireAuth>}/>       
          <Route element={<RequireAuth><ReminderPage /></RequireAuth>} path="/services/:serviceId/reminder" />
          <Route element={<RequireAuth><EventsPage /></RequireAuth>} path="/services/:serviceId/sys-events" />
          <Route element={<RequireAuth><DevicePage /></RequireAuth>} path="/services/:serviceId/device" />
          <Route element={<RequireAuth><MemberPage /></RequireAuth>} path="/services/:serviceId/member">
            <Route element={<RequireAuth><NewMemberPage setForceUpdate={null} /></RequireAuth>} path="newMember" />
            <Route element={<RequireAuth><EditMemberPage setForceUpdate={null} /></RequireAuth>} path="editMember" />
          </Route>
          <Route element={<RequireAuth><SettingsPage /></RequireAuth>} path="/services/:serviceId/sys-settings"/>
          <Route element={<RequireAuth><EditPasswordPage /></RequireAuth>} path="/services/:serviceId/sys-settings/editPassword" />
          <Route element={<RequireAuth><EditBasicInfoPage /></RequireAuth>} path="/services/:serviceId/sys-settings/editBasicInfo" />          
          <Route element={<RequireAuth><EditPermissionsPage /></RequireAuth>} path="/services/:serviceId/sys-settings/editPermissions"/>
          <Route element={<RequireAuth><PermissionsPage /></RequireAuth>} path="/services/:serviceId/sys-settings/editPermissions/permissions" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

