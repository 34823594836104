import { Image } from 'antd-mobile'
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { RegisterPage } from './register';
import { ForgotPasswordPage } from './forgotPassword';
import { SetRequestPage } from './forgotPassword/setRequest';
import { EnterPasswordPage } from './forgotPassword/enterPassword';
import { LoginFormPage } from './loginForm';


const PageContainer=styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const LoginPage = () => {
    const imageUrlPrefix = '../home/image'
    const location = useLocation()

  return (
    <PageContainer>
        {
            location.pathname.split('/').length<4
            ?
            (
                location.pathname === '/login/register'?
                <Image src={`${imageUrlPrefix}/logo.png`} width={150} height={150} alt='button' fit='fill' />
                :
                <Image src={`${imageUrlPrefix}/logo.png`} width={250} height={250} alt='button' fit='fill' />
            )
            :
            <></>
        }
        {
            location.pathname === '/login'&&
            <LoginFormPage/>
        }
        {
            location.pathname === '/login/register'&&
            <RegisterPage/>
        }
        {
            location.pathname === '/login/forgotPassword'&&
            <ForgotPasswordPage/>
        }
        {
            location.pathname === '/login/forgotPassword/setRequest'&&
            <SetRequestPage/>
        }
        {
            location.pathname === '/login/forgotPassword/enterPassword'&&
            <EnterPasswordPage/>
        }
    </PageContainer>
  )
}

