import { gql } from "graphql-request"
import { applicationClient, makeGraphQLRequest } from "../url"
import { EventQuery } from "../model/application/eventQuery"
import { PageInfo } from "../model/application/pageInfo"
import { Event } from "../model/application/event"
import { SERVER_TOKEN } from "../../idb"

interface EventResult{
    content:Event[]
    pageInfo:PageInfo
}


export const ApiGetEvents=async(input:EventQuery)=>{
    const queryStr=gql`
        query ApiGetEvents($query:EventQuery!){
            getEvents(query:$query){
                content{
                    id
                    time
                    type
                    title
                    user{
                        name
                    }
                }
                pageInfo{
                    totalPages
                    totalElements
                    numberOfElements
                    number
                    size
                }
            }
        }
    `
    const variables={
        query:input
    }

    const token=localStorage.getItem(SERVER_TOKEN)
    applicationClient.setHeader("authorization",`Bearer ${token}`)
    
    try {
        const res=makeGraphQLRequest<{getEvents:EventResult}>(queryStr,variables,applicationClient)

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to get events");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while getting events");
    }

}