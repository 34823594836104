import { Form, Input,Image, Button } from 'antd-mobile'
import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { ApiSendPasswordResetCode } from '../../../../graphql/query/sendPasswordResetCode'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { notification } from 'antd'
import { ApiCheckPasswordResetCode } from '../../../../graphql/query/checkPasswordResetCode'

const SubTitle=styled.div`
    color:#ebac53;
    font-size:18px;
    font-weight:800;
    text-align:center;
    margin-top:-5px;
`

const FormContainer=styled.div`
    margin-top: 10px;
    padding: 0 10px;
    width: 100%;
`

const LoginText=styled.div`
    display:flex;
    justify-content:center;
    margin-top:15px;
`

export const SetRequestPage = () => {
    const imageUrlPrefix = '../../home/image'
    const [input,setInput]=React.useState<{code:string}>({code:''})
    const [btnDisabled,setBtnDisabled]=React.useState(true)
    const navigate=useNavigate()
    const [getParams] = useSearchParams()
    const queryAccount=getParams.get('account')||""
    const [btnState,setBtnState]=React.useState<{text:string,bgcolorIsBlue:boolean}>({text:'GET VERIFICATION CODE',bgcolorIsBlue:true})

    useEffect(()=>{
        checkBtnDisabled()
    },[input])

    const openSuccessNotification = () => {
        notification.success({
            message: 'Success',
            description: 'Verification code has been sent to your email',
        });
    }

    const openFailedNotification = (description:string) => {
        notification.error({
            message: 'Error',
            description: `${description}`,
        });
    }

    const handleGetResetCode=()=>{
        setBtnState({text:'',bgcolorIsBlue:false})
        ApiSendPasswordResetCode(queryAccount||'')
        .then((res)=>{
            if(res.sendPasswordResetCode===true){
                openSuccessNotification()
                setTimer()
            }
            else{
                openFailedNotification('Failed to send verification code')
            }
        })
        .catch((err)=>{
            openFailedNotification('Failed to send verification code')
        })
    }

    const handleCheckCode=()=>{
        ApiCheckPasswordResetCode(queryAccount||'',input.code)
        .then((res)=>{
            if(res.checkPasswordResetCode===true){
                navigate(`/login/forgotPassword/enterPassword?account=${queryAccount}&code=${input.code}`)
            }
            else{
                openFailedNotification('Invalid verification code')
            }
        })
        .catch((err)=>{
            openFailedNotification('Reset code not found or time limit expired')
        })
    }

    const setTimer=()=>{
        let time=60
        const timer=setInterval(()=>{
            if(time>0){
                time--
                setBtnState({text:`${time} Sec`,bgcolorIsBlue:false})
            }
            else{
                clearInterval(timer)
                setBtnState({text:'GET VERIFICATION CODE',bgcolorIsBlue:true})
            }
        },1000)
    }

    const checkBtnDisabled=()=>{
        if(input.code!==""&&input.code.length>5){
            setBtnDisabled(false)
        }
        else{
            setBtnDisabled(true)
        }
    }

  return (
    <>
        <Image src={`${imageUrlPrefix}/logo.png`} width={250} height={250} alt='button' fit='fill' />
        <SubTitle>Enter verification code</SubTitle>
        <FormContainer>
            <div style={{display:'flex'}}>
                <Form layout='horizontal' style={{marginBottom:'15px',width:'50%'}}>
                    <Form.Item label='' name='account' rules={[{ type:'string',message: 'Invalid format'}]}>
                        <Input placeholder='Verification Code' clearable value={input.code} onChange={(val:string)=>{setInput({...input,code:val})}}/>
                    </Form.Item>
                </Form>
                <Button block color='primary' size='small' disabled={!btnState.bgcolorIsBlue} className={btnState.bgcolorIsBlue?'':'colorPrimaryDisabled'} style={{width:'50%',height:'52px'}} 
                    onClick={()=>{
                        handleGetResetCode()
                    }}>
                        {btnState.text}
                    </Button>
            </div>
            <Button block color='primary' size='middle' className='colorPrimary'
                disabled={btnDisabled}
                onClick={()=>{
                    handleCheckCode()
                }}>
                VERIFICATION CODE CANNOT BE EMPTY 
            </Button>
            <LoginText>
                <span style={{fontSize:'16px'}}>Cancel Request?
                <a href="/login" style={{fontSize:'16px',margin:'15px 20px',border:'none',textDecoration:'none',color:'#75BD76'}}>Login</a></span>
            </LoginText>
        </FormContainer>

        
    </>
  )
}
