import { notification, Spin } from 'antd'
import React, { useEffect } from 'react'
import { Image, InfiniteScroll, List } from 'antd-mobile'
import { PageLayout } from '../../components/pageLayout'
import { PageNavbar } from '../../components/pageNavbar'
import { ApiGetEvents } from '../../../graphql/query/getEvents'
import moment from 'moment'
import { EventQuery } from '../../../graphql/model/application/eventQuery'
import { useParams } from 'react-router-dom'

interface ItemDataProps{
  id:string
  type:string
  title:string
  time:string
  userName:string
}

export const EventsPage = () => {
  const [loading, setLoading] = React.useState(false)
  const imageUrlPrefix = '../../events/image'
  const [itemData,setItemData]=React.useState<ItemDataProps[]>([])
  const [hasMore, setHasMore] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(0)
  const { serviceId } = useParams<{ serviceId: string }>();

  const pageSize = 10

  useEffect(()=>{
    firstGetEvents()
  },[])

  const openFailedNotification = (description:string) => {
    notification.error({
      message: 'Error',
      description: `${description}`,
    });
  }

  const firstGetEvents=()=>{
      setLoading(true)
      const newInput:EventQuery={
          page:{
              page:currentPage+1,
              size:pageSize
          },
          serviceID:serviceId||'',
      }
      
      ApiGetEvents(newInput)
      .then((res)=>{
        if(res.getEvents!==null){
          // console.log("res.getEvents",res.getEvents)
          const events:ItemDataProps[]=[]
          for(let i=0;i<res.getEvents.content.length;i++){
            events.push(
              {
                id:res.getEvents.content[i]?.id,
                type:res.getEvents.content[i]?.type,
                time:res.getEvents.content[i]?.time,
                title:res.getEvents.content[i]?.title,
                userName:res.getEvents.content[i]?.user.name
              })
          }
          setItemData(events)
          setCurrentPage(currentPage+1)
          if (currentPage>=res.getEvents.pageInfo.totalPages){
            setHasMore(false)          
          }else{
            setHasMore(true)
          }
        }
        else{
          openFailedNotification("Failed to get data")
        }
      })
      .catch((err)=>{
        openFailedNotification("Failed to get data: "+err)
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  const loadMore = async() => {
    const newInput:EventQuery={
        page:{
            page:currentPage+1,
            size:pageSize
        },
        serviceID:serviceId||'',
    }
      ApiGetEvents(newInput)
      .then((res)=>{
        if(res.getEvents!==null){
          console.log("res.getEvents",res.getEvents)
          const events:ItemDataProps[]=[]
          for(let i=0;i<res.getEvents.content.length;i++){
            events.push(
              {
                id:res.getEvents.content[i]?.id,
                type:res.getEvents.content[i]?.type,
                time:res.getEvents.content[i]?.time,
                title:res.getEvents.content[i]?.title,
                userName:res.getEvents.content[i]?.user.name
              })
          }
          setItemData([...itemData,...events])
          setCurrentPage(currentPage+1)
          if (currentPage>=res.getEvents.pageInfo.totalPages){
            setHasMore(false)
          }else{
            setHasMore(true)
          }
        }
        else{
          openFailedNotification("Failed to get data")
        }
      })
      .catch((err)=>{
        openFailedNotification("Failed to get data: "+err)
      })
      .finally(()=>{

      })
  }

  return (
      <>
          <Spin spinning={loading} tip="Loading" size="large" fullscreen/>
          <PageLayout 
            header={
              <PageNavbar title="Event" backTo={"/services/"+serviceId}/>
            }
            content={
              <>
                <List>
                  {
                    itemData.map((item:ItemDataProps,index:number)=>(
                      <List.Item
                        key={item.id}
                        style={{ paddingRight:'20px' }}
                        prefix={
                          item.type==='ALARM'?
                          <Image
                            src={`${imageUrlPrefix}/history-list-alarm.png`}
                            style={{ borderRadius: 20 }}
                            fit='contain'
                            width={40}
                            height={40}
                          />
                          :
                          <Image
                            src={`${imageUrlPrefix}/history-list-warn.png`}
                            style={{ borderRadius: 20 }}
                            fit='contain'
                            width={40}
                            height={40}
                          />
                        }
                        description={item.userName}
                        extra={
                          <div style={{textAlign:'center'}}>
                            {moment(`${item.time}`).format('MM-DD')}<br/>
                            {moment(`${item.time}`).format('a h:mm')}
                          </div>
                        }
                      >
                        {item.title}
                      </List.Item>
                    ))
                  }
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
              </>
            }
          />
      </>
  )
}
