import { Button, Modal, Space, Typography } from 'antd'
import React from 'react'

interface UnlockAlarmModalProps {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    unlockFunction:()=>void
}

export const UnlockAlarmModal = (props:UnlockAlarmModalProps) => {
  return (
    <>
        <Modal
            title={ 
            <>
                <Typography.Text strong style={{fontWeight:'600',fontSize:'16px'}}>
                    Are you sure you want to unlock the alarm?
                </Typography.Text>
            </>
            }
            style={{top:"30%",height:'55px',zIndex:99}}
            width={'80%'}
            open={props.visible}
            onCancel={()=>{props.setVisible(false)}}
            maskClosable={false}
            closable={false}
            footer={
                <Space>
                    <Button color="primary" size='small' className='colorPrimary'
                    onClick={()=>{
                        props.setVisible(false)
                    }}>Cancel</Button>
                    <Button color="primary" size='small' className='colorPrimary'
                        onClick={()=>{
                            props.setVisible(false)
                            // handleUnlockAlarm()
                            props.unlockFunction()
                        }}>Confirm</Button>
                </Space>
            }
        >
        </Modal>
    </>
  )
}
