import { gql } from "graphql-request"
import { makeGraphQLRequest, authClient } from "../url"
import { SERVER_TOKEN } from "../../idb"


export const ApiChangePassword=async(oldPassword:string,newPassword:string)=>{
    const mutationStr=gql`
        mutation ApiChangePassword($oldPassword:String!,$newPassword:String!){
            changePassword(oldPassword:$oldPassword,newPassword:$newPassword)
        }
    `
    const variables={
        oldPassword:oldPassword,
        newPassword:newPassword
    }
    const token=localStorage.getItem(SERVER_TOKEN)
    authClient.setHeader("authorization",`Bearer ${token}`)

    try {
        const res = await makeGraphQLRequest<{ changePassword: boolean }>(mutationStr, variables,authClient);

        if (!res || typeof res !== "object" ) {
            console.error("GraphQL Error:", "Invalid response");
            throw new Error("Failed to change password");
        }

        return res
    } catch (error) {
        console.error("API Error:", error);
        throw new Error("An error occurred while changing the password");
    }

}